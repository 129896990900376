import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import webshopOperations from "./webshop-operations";
import { ErrorResponse, PagedResponseType } from "src/@types/commons";
import { BasketRuleSearchResult, BasketRuleStatistics, BasketRuleType, DEFAULT_BASKET_RULES_STATISTICS, EnableDisableBasketRuleSliceProps, ShopSearchResult, Shop, ShopStatistics, DEFAULT_SHOP_STATISTICS, EnableDisableShopSliceProps, ShopDisplay } from "src/@types/webshop";
import { DEFAULT_LOGS_DATA, LogsItem } from "src/@types/logs";

interface WebshopReducer {
    error: ErrorResponse,
    isLogsLoading: boolean,
    //---- BASKET RULES --------------
    isBasketRuleLoading: boolean,
    isBasketRuleInfiniteScrolling: boolean,
    basketRule: BasketRuleType | null,
    basketRulesTotalCount: number,
    basketRuleFiltersInUrl: string,
    basketRuleList: BasketRuleSearchResult[],
    basketRuleLogs: PagedResponseType<LogsItem>,
    basketRuleStatistics: BasketRuleStatistics,
    //---- WEBSHOP -------------------
    isWebshopLoading: boolean,
    isWebshopInfiniteScrolling: boolean,
    webshop: Shop | null,
    displayShop: ShopDisplay | null
    webshopTotalCount: number,
    webshopFiltersInUrl: string,
    webshopList: ShopSearchResult[],
    webshopLogs: PagedResponseType<LogsItem>,
    webshopStatistics: ShopStatistics
}

const initialState: WebshopReducer = {
    error: null,
    isLogsLoading: false,
    //---- BASKET RULES --------------
    isBasketRuleLoading: false,
    isBasketRuleInfiniteScrolling: false,
    basketRule: null,
    basketRulesTotalCount: 0,
    basketRuleFiltersInUrl: "",
    basketRuleList: [],
    basketRuleLogs: DEFAULT_LOGS_DATA,
    basketRuleStatistics: DEFAULT_BASKET_RULES_STATISTICS,
    //---- WEBSHOP -------------------
    isWebshopLoading: false,
    isWebshopInfiniteScrolling: false,
    webshop: null,
    displayShop: null,
    webshopTotalCount: 0,
    webshopFiltersInUrl: "",
    webshopList: [],
    webshopLogs: DEFAULT_LOGS_DATA,
    webshopStatistics: DEFAULT_SHOP_STATISTICS
};

const WebshopSlice = createSlice({
    name: "webshop",
    initialState,
    extraReducers: (builder) => {
        builder
            //---- BASKET RULES -----------------------------------------------------------------
            .addCase(webshopOperations.createBasketRule.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isBasketRuleLoading = false;
            })
            .addCase(webshopOperations.createBasketRule.fulfilled, (state, action) => {
                state.basketRule = action.payload;
                state.isBasketRuleLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.updateBasketRule.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isBasketRuleLoading = false;
            })
            .addCase(webshopOperations.updateBasketRule.fulfilled, (state, action) => {
                state.basketRule = action.payload;
                state.isBasketRuleLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.getBasketRule.rejected, (state, action) => {
                state.basketRule = initialState.basketRule;
                state.error = action.error.message as string;
                state.isBasketRuleLoading = false;
            })
            .addCase(webshopOperations.getBasketRule.fulfilled, (state, action) => {
                state.basketRule = action.payload;
                state.isBasketRuleLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.searchBasketRules.rejected, (state, action) => {
                state.basketRuleList = initialState.basketRuleList;
                state.error = action.error.message as string;
                state.isBasketRuleLoading = false;
                state.isBasketRuleInfiniteScrolling = false;
            })
            .addCase(webshopOperations.searchBasketRules.fulfilled, (state, action) => {
                state.basketRuleList = state.isBasketRuleInfiniteScrolling ? state.basketRuleList.concat(action.payload.results) : action.payload.results;
                state.basketRulesTotalCount = action.payload.totalCount;
                state.isBasketRuleLoading = false;
                state.isBasketRuleInfiniteScrolling = false;
            })
            //-----------------------
            .addCase(webshopOperations.enableDisableBasketRule.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            //-----------------------
            .addCase(webshopOperations.getBasketRuleLogs.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.basketRuleLogs = initialState.basketRuleLogs;
                state.isLogsLoading = false;
            })
            .addCase(webshopOperations.getBasketRuleLogs.fulfilled, (state, action) => {
                state.basketRuleLogs = action.payload;
                state.isLogsLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.getBasketRuleStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.basketRuleStatistics = initialState.basketRuleStatistics;
            })
            .addCase(webshopOperations.getBasketRuleStatistics.fulfilled, (state, action) => {
                state.basketRuleStatistics = action.payload;
            })
            //---- WEBSHOP ----------------------------------------------------------------------
            .addCase(webshopOperations.createShop.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isWebshopLoading = false;
            })
            .addCase(webshopOperations.createShop.fulfilled, (state, action) => {
                state.webshop = action.payload;
                state.isWebshopLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.updateShop.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isWebshopLoading = false;
            })
            .addCase(webshopOperations.updateShop.fulfilled, (state, action) => {
                state.webshop = action.payload;
                state.isWebshopLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.getShop.rejected, (state, action) => {
                state.webshop = initialState.webshop;
                state.error = action.error.message as string;
                state.isWebshopLoading = false;
            })
            .addCase(webshopOperations.getShop.fulfilled, (state, action) => {
                state.webshop = action.payload;
                state.isWebshopLoading = false;
            })
            .addCase(webshopOperations.getDisplayShop.fulfilled, (state, action) => {
                state.displayShop = action.payload;
                state.isWebshopLoading = false;
            })
            .addCase(webshopOperations.getDisplayShop.rejected, (state, action) => {
                state.displayShop = initialState.displayShop;
                state.error = action.error.message as string;
                state.isWebshopLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.searchShops.rejected, (state, action) => {
                state.webshopList = initialState.webshopList;
                state.error = action.error.message as string;
                state.isWebshopLoading = false;
                state.isWebshopInfiniteScrolling = false;
            })
            .addCase(webshopOperations.searchShops.fulfilled, (state, action) => {
                state.webshopList = state.isWebshopInfiniteScrolling ? state.webshopList.concat(action.payload.results) : action.payload.results;
                state.webshopTotalCount = action.payload.totalCount;
                state.isWebshopLoading = false;
                state.isWebshopInfiniteScrolling = false;
            })
            //-----------------------
            .addCase(webshopOperations.getShopLogs.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.webshopLogs = initialState.webshopLogs;
                state.isLogsLoading = false;
            })
            .addCase(webshopOperations.getShopLogs.fulfilled, (state, action) => {
                state.webshopLogs = action.payload;
                state.isLogsLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.getShopStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.webshopStatistics = initialState.webshopStatistics;
            })
            .addCase(webshopOperations.getShopStatistics.fulfilled, (state, action) => {
                state.webshopStatistics = action.payload;
            })
            ;
    },
    reducers: {
        startWebshopLoading(state, action: PayloadAction<boolean>) {
            state.isWebshopLoading = true;
            if (action.payload) state.isWebshopInfiniteScrolling = true;
        },
        startBasketLoading(state, action: PayloadAction<boolean>) {
            state.isBasketRuleLoading = true;
            if (action.payload) state.isBasketRuleInfiniteScrolling = true;
        },
        startLogsLoading(state) {
            state.isLogsLoading = true;
        },
        setBasketFiltersInUrl(state, action: PayloadAction<string>) {
            state.basketRuleFiltersInUrl = action.payload;
        },
        setWebshopFiltersInUrl(state, action: PayloadAction<string>) {
            state.webshopFiltersInUrl = action.payload;
        },
        resetWebshop(state) {
            state.webshop = null;
        },
        //RESET BASKET RULES LIST
        resetBasketList(state) {
            state.basketRuleList = initialState.basketRuleList;
        },
        resetWebshopList(state) {
            state.webshopList = initialState.webshopList;
        },
        //DISABLE/ENABLE IN LIST AFTER SERVICE
        enableDisableBasketSL(state, action: PayloadAction<EnableDisableBasketRuleSliceProps>) {

            const { id, tab, action: payloadAction } = action.payload;

            const index = state.basketRuleList.findIndex((basketRule) => basketRule.id === id);

            state.basketRuleList[index].enabled = payloadAction === "Enable";

            if (tab !== "") state.basketRuleList.splice(index, 1);

            if (payloadAction === "Enable") {
                state.basketRuleStatistics.disabled -= 1;

                state.basketRuleStatistics.enabled += 1;
            } else {
                state.basketRuleStatistics.disabled += 1;

                state.basketRuleStatistics.enabled -= 1;
            }
        },
        //DISABLE/ENABLE IN LIST AFTER SERVICE
        enableDisableWebshopSL(state, action: PayloadAction<EnableDisableShopSliceProps>) {

            const { id, tab, action: payloadAction } = action.payload;

            const index = state.webshopList.findIndex((basketRule) => basketRule.id === id);

            state.webshopList[index].enabled = payloadAction === "Enable";

            if (tab !== "") state.webshopList.splice(index, 1);

            if (payloadAction === "Enable") {
                state.webshopStatistics.disabled -= 1;

                state.webshopStatistics.expired += 1;
            } else {
                state.webshopStatistics.disabled += 1;

                state.webshopStatistics.expired -= 1;
            }
        }
    }
});

export const {
    startWebshopLoading,
    startBasketLoading,
    startLogsLoading,
    resetBasketList,
    resetWebshopList,
    enableDisableBasketSL,
    setBasketFiltersInUrl,
    setWebshopFiltersInUrl,
    enableDisableWebshopSL,
    resetWebshop
} = WebshopSlice.actions;

export default WebshopSlice.reducer;