import { SnackbarOrigin } from "notistack";

export type PriorityEnum = "High" | "Medium" | "Low";

export type AlternativePagination = "After" | "Before"

export type PagedResponseType<T> = {
    hasNextPage?: boolean,
    hasPreviousPage?: boolean,
    pageIndex: number,
    pageSize: number,
    items: T[],
    totalCount: number,
    totalPages?: number
}

export interface SequenceToken {
    searchSequenceToken: string | null
}

export type InfiniteScrollPagedResponse<T extends SequenceToken> = {
    results: T[],
    totalCount: number
}

export type InfiniteScrollGenericListFilters = {
    [key: string]: any
    size: number,
    sequenceToken: string | null,
    pagination: "After" | "Before",
    sortField: string,
    sortDirection: "Ascending" | "Descending",
    status?: string
}

export const INFINITE_SCROLL_GENERIC_LIST_FILTERS: InfiniteScrollGenericListFilters = {
    size: 11,
    sequenceToken: null,
    pagination: "After",
    sortField: "createdOn",
    sortDirection: "Descending"
};

export type LocalizedString = {
    text: string | null;
    culture: string;
}

export type CustomOptions = {
    lang: string,
    optionList: string[]
}

export type Tag = {
    text: string;
    numberOfTimesUsed: number;
}

export type Suggest = {
    field: string;
    count: number;
}

export type StorageUriHttpResponse = {
    uploadResourceUrl: string;
    publicResourceUrl: string;
}

export enum MediaTypes {
    Image = 0,
    Video = 1,
    Audio = 2,
    Attach = 3
}

export type ToolbarSearchFilters = {
    key: string;
    label: string;
};

export type SelectItem = {
    value: string,
    label: string
};

export type PowerSearchFilters = {
    pageIndex: number,
    pageSize: number,
    all: string
};

export type ErrorResponse = null | string | { [key: string]: string[] }

//--------------------------------------------------
//TODO: metterlo ovunque
export const DEFAULT_LOGS: PagedResponseType<any> = {
    pageIndex: 0,
    pageSize: 0,
    items: [],
    totalCount: 0
};

//--------------------------------------------------
type DefaultSnackbarType = {
    variant: "success" | undefined,
    autoHideDuration: number | null | undefined,
    anchorOrigin: SnackbarOrigin | undefined
};

export const DEFAULT_SNACKBAR_PROPERTIES: DefaultSnackbarType = {
    variant: "success",
    autoHideDuration: 3000,
    anchorOrigin: { vertical: "top", horizontal: "right" } as SnackbarOrigin
};