import { Box, Button, Card, Divider, FormControlLabel, Grid, Radio, Stack, TextField, Theme, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import GridOnIcon from '@mui/icons-material/GridOn';
import BoxSwitch from "src/components/custom-input/BoxSwitch";
import StepButtons from "src/components/buttons/StepButtons";
import { Add, DeleteForever } from "@mui/icons-material";
import { cloneDeep } from "lodash";
import InfiniteAutocomplete from "src/appComponents/InfiniteAutocomplete";
import { useLocales } from "src/locales";

interface DiscountModelStepProps {
    changeStep: Dispatch<SetStateAction<number>>
}

export default function DiscountModelStep({ changeStep }: DiscountModelStepProps) {

    const { translate } = useLocales();

    const [button, setButton] = useState<"" | "FixedPrice" | "MatrixDiscount">("");

    return (
        <Card sx={{ p: 3 }}>
            <Box>
                <Typography variant="h6">
                    {translate('webshop.form.step.discountModels')}
                </Typography>
                <ToggleButtonGroup
                    sx={{
                        gridTemplateColumns: '1fr 1fr',
                        display: 'grid',
                        border: 'none',
                        m: 2,
                        gap: 3,
                    }}
                    onChange={(_, v) => setButton(v)}
                    value={button}
                    exclusive
                    size="large"
                    color="primary"
                >
                    <ToggleButton
                        value={"FixedPrice"}
                        sx={{
                            outlineWidth: '1px',
                            outlineStyle: 'solid',
                            ml: '-1px !important',
                            outlineColor: '#E2E2E2',
                            p: 3
                        }}
                    >
                        <Grid item xs={4}>
                            <Stack
                                sx={{
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <DoNotDisturbAltIcon />
                                <Typography sx={{ mt: 1 }} variant="subtitle2">
                                    {translate('webshop.form.fixedPrice')}
                                </Typography>
                            </Stack>
                        </Grid>
                    </ToggleButton>
                    <ToggleButton
                        value={"MatrixDiscount"}
                        sx={{
                            outlineWidth: '1px',
                            outlineStyle: 'solid',
                            ml: '-1px !important',
                            outlineColor: '#E2E2E2',
                            p: 3
                        }}
                    >
                        <Grid item xs={4}>
                            <Stack
                                sx={{
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <GridOnIcon />
                                <Typography sx={{ mt: 1 }} variant="subtitle2">
                                    {translate('webshop.form.matrixDiscount')}
                                </Typography>
                            </Stack>
                        </Grid>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {button === "FixedPrice" && <FixedPrice />}
            {button === "MatrixDiscount" &&
                <Box>
                    <Box sx={{ my: 2 }}>
                        <Typography sx={{ mb: 1 }} variant="subtitle2">{translate('webshop.form.selectMatrix')}</Typography>
                        <InfiniteAutocomplete

                            searchCallBack={() => new Promise<any>(() => { })}
                            renderInput={(params) => <TextField label={"Matrix Discount"} {...params} />} />
                    </Box>
                    <MatrixDiscount />
                </Box>
            }
            <Divider sx={{ my: 3 }} />
            <StepButtons changeStep={changeStep} />
        </Card>
    );
}

const boxStyle = {
    p: 3,
    width: '100%',
    border: "1px solid #E2E2E2",
    borderRadius: '8px',
    ":focus-within": {
        borderColor: (theme: Theme) => theme.palette.primary.main
    }
};

function FixedPrice() {

    const { translate } = useLocales();

    const allFalse = {
        fixedOnly: false,
        single: false,
        multiple: false
    };

    const [switches, setSwitches] = useState(allFalse);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 3 }}>
            <Typography variant="subtitle2">{translate('webshop.form.selectOption')}</Typography>
            <Box sx={boxStyle} >
                <BoxSwitch
                    value={switches.fixedOnly}
                    onChange={(v) => setSwitches({ ...allFalse, fixedOnly: v })}
                    label={translate('webshop.form.fixedOnly')}
                />
            </Box>
            <Box sx={boxStyle}>
                <BoxSwitch
                    value={switches.single}
                    onChange={(v) => setSwitches({ ...allFalse, single: v })}
                    label={translate('webshop.form.fixedSingleOrder')}
                />
                {switches.single &&
                    <TextField sx={{ mt: 2 }} label={translate('webshop.form.additionalDiscount')} />
                }
            </Box>
            <Box sx={boxStyle} >
                <BoxSwitch
                    value={switches.multiple}
                    onChange={(v) => setSwitches({ ...allFalse, multiple: v })}
                    label={translate('webshop.form.fixedMultipleOrder')}
                />
                {switches.multiple &&
                    <RangesRadio />
                }
            </Box>
        </Box>
    );
}

function MatrixDiscount() {

    const { translate } = useLocales();

    const allFalse = {
        gross: false,
        flat: false,
        flex: false,
        multiple: false
    };

    const [switches, setSwitches] = useState(allFalse);

    const [radio, setRadio] = useState<number | null>(null);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 3 }}>
            <Typography variant="subtitle2">{translate('webshop.form.selectOption')}</Typography>
            <Box sx={boxStyle} >
                <BoxSwitch
                    value={switches.gross} onChange={(v) => setSwitches({ ...allFalse, gross: v })}
                    label={translate('webshop.form.grossDiscount')}
                    subtitle={translate('webshop.messages.grossDiscountSubtitle')} />

                {switches.gross &&
                    <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                        <FormControlLabel
                            label={translate('webshop.form.withoutThreshold')}
                            onChange={() => setRadio(0)}
                            control={<Radio checked={radio === 0} />}
                        />
                        <FormControlLabel
                            label={translate('webshop.form.withThreshold')}
                            onChange={() => setRadio(1)}
                            control={<Radio checked={radio === 1} />}
                        />
                        {radio === 1 &&
                            <Box>
                                <Ranges />
                            </Box>
                        }
                    </Box>
                }
            </Box>
            <Box sx={boxStyle}>
                <BoxSwitch
                    label={translate('webshop.form.discountFlat')}
                    subtitle={translate('webshop.messages.discountFlatSubtitle')}

                    value={switches.flat} onChange={(v) => setSwitches({ ...allFalse, flat: v })}

                />
                {switches.flat &&
                    <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', justifyItems: 'center' }}>
                            <FormControlLabel
                                label={<span>{translate('webshop.form.additionalDiscountCalc')} <b>{translate('webshop.form.netPrice')}</b></span>}
                                onChange={() => setRadio(0)}
                                control={<Radio checked={radio === 0} />}
                            />
                            <TextField
                                disabled={radio !== 0}
                                label={translate('webshop.form.additionalDiscount')}
                                sx={{
                                    width: '30%',
                                    backgroundColor: (theme) => radio !== 0 ? theme.palette.action.disabledBackground : undefined,
                                    borderRadius: '8px'
                                }} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', justifyItems: 'center' }}>
                            <FormControlLabel
                                label={<span>{translate('webshop.form.additionalDiscountCalc')} <b>{translate('webshop.form.grossPrice')}</b></span>}
                                onChange={() => setRadio(1)}
                                control={<Radio checked={radio === 1} />}
                            />
                            <TextField
                                disabled={radio !== 1}
                                label={translate('webshop.form.additionalDiscount')}
                                sx={{
                                    width: '30%',
                                    backgroundColor: (theme) => radio !== 1 ? theme.palette.action.disabledBackground : undefined,
                                    borderRadius: '8px'
                                }} />
                        </Box>
                    </Box>
                }
            </Box>
            <Box sx={boxStyle} >
                <BoxSwitch
                    value={switches.flex} onChange={(v) => setSwitches({ ...allFalse, flex: v })}

                    label={translate('webshop.form.discountFlexible')}
                    subtitle={translate('webshop.messages.discountFlexibleSubtitle')} />

            </Box>
            <Box sx={boxStyle} >
                <BoxSwitch
                    value={switches.multiple} onChange={(v) => setSwitches({ ...allFalse, multiple: v })}

                    label={translate('webshop.form.discountMultipleLevel')} />

                {switches.multiple && <RangesRadio />}
            </Box>
        </Box>
    );
}

type Range = {
    from: number,
    to: number,
    discount: number
}

function Ranges() {

    const { translate } = useLocales();

    const [ranges, setRanges] = useState<Range[]>([]);

    const handleAddRange = () => {
        setRanges(prev => {
            if (!prev.length)
                return [{ discount: 0, from: 0, to: 0 }];

            const val = { discount: 0, from: prev[prev.length - 1].to, to: 0 };

            return prev.concat([val]);

        });

    };

    const handleChangeRange = (index: number, name: string, value: number) => {

        setRanges(prev => {
            const copy = cloneDeep(prev);

            copy[index] = { ...copy[index], [name]: value };

            return copy;

        });
    };

    const handleDelete = (index: number) => {
        setRanges(prev => {
            return prev.filter((_, i) => i !== index);
        });
    };

    return (
        <Box>
            {ranges.map((r, index) =>
                <RangeRow
                    handleChangeRange={(n, v) => handleChangeRange(index, n, v)}
                    handleDelete={() => handleDelete(index)}
                    row={r}
                    key={index}
                />

            )}
            <Button
                variant="soft"
                size={"large"}
                fullWidth

                startIcon={<Add />}
                onClick={() => handleAddRange()}
            >
                {translate('webshop.form.addRange')}

            </Button>
        </Box>
    );

}

function RangesRadio() {

    const { translate } = useLocales();

    const [radio, setRadio] = useState(0);

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 1 }}>
                <FormControlLabel
                    label={translate('webshop.form.singleOrder')}
                    onChange={() => setRadio(1)}
                    control={<Radio checked={radio === 1} />}
                />
                {
                    radio === 1 &&
                    <Ranges />
                }
                <FormControlLabel
                    label={translate('webshop.form.multipleOrder')}
                    onChange={() => setRadio(2)}
                    control={<Radio checked={radio === 2} />}
                />
                {
                    radio === 2 &&
                    <Ranges />
                }
            </Box>
        </Box>
    );
}

interface RangeRowProps {
    row: Range,
    handleChangeRange: (name: string, value: number) => void,
    handleDelete: VoidFunction
}

function RangeRow({ handleChangeRange, row, handleDelete }: RangeRowProps) {

    const { translate } = useLocales();

    return (
        <Box sx={{
            my: 2,
            width: '100%',
            display: 'flex',
            mt: 2, gap: 2,
            alignSelf: 'stretch',
            alignItems: 'center'
        }}>
            <TextField onChange={(e) => handleChangeRange('from', +e.target.value)} value={row.from} fullWidth label={translate('commons.from')} />
            <TextField onChange={(e) => handleChangeRange('to', +e.target.value)} value={row.to} fullWidth label={translate('commons.to')} />
            <TextField onChange={(e) => handleChangeRange('discount', +e.target.value)} value={row.discount} fullWidth label={translate('documents.documentHeaders.discount')} />
            <Button
                size={"medium"}
                startIcon={<DeleteForever sx={{ ml: 1.5 }} />}
                onClick={() => handleDelete()}
            />

        </Box>
    );
}