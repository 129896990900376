import StarterKit from "@tiptap/starter-kit";
import { useRef } from "react";
import {
    MenuButtonAlignCenter,
    MenuButtonAlignJustify,
    MenuButtonAlignLeft,
    MenuButtonAlignRight,
    MenuButtonBold,
    MenuButtonBulletedList,
    MenuButtonItalic,
    MenuButtonOrderedList,
    MenuButtonStrikethrough,
    MenuButtonUnderline,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectFontFamily,
    RichTextEditor,
    RichTextEditorRef,
} from "mui-tiptap";
import { Theme, Box, Typography } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import FontFamily from '@tiptap/extension-font-family';
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import { useLocales } from "src/locales";

interface RichEditorProps {
    sx?: SystemStyleObject<Theme>,
    value?: string,
    onChange?: (val: string, normal: string) => void,
    getText?: (val: string) => void,
    error?: boolean,
    errorHelperText?: string
}

export default function RichEditor({ sx, onChange, value, getText, error, errorHelperText }: RichEditorProps) {

    const rteRef = useRef<RichTextEditorRef>(null);

    const { translate } = useLocales();

    return (
        <Box sx={{

            "& .MuiTiptap-FieldContainer-notchedOutline": {
                borderColor: theme => error ? `${theme.palette.error.main} !important` : undefined
            },
            //editor style
            "& .ProseMirror": {
                ...sx,
                borderColor: 'red'
            }
        }}>
            <RichTextEditor
                ref={rteRef}
                extensions={[
                    StarterKit,
                    TextStyle,
                    FontFamily,
                    Underline,
                    TextAlign.configure({ types: ["heading", "paragraph"], defaultAlignment: 'left', alignments: ['left', 'center', 'right', 'justify'] })
                ]} // Or any Tiptap extensions you wish!
                content={value}
                onUpdate={(val) => {
                    if (onChange)
                        onChange(val.editor.getHTML(), val.editor.getText());
                    if (getText)
                        getText(val.editor.getText());
                }}
                // Optionally include `renderControls` for a menu-bar atop the editor:
                renderControls={() => (
                    <MenuControlsContainer>
                        <MenuSelectFontFamily
                            options={[
                                { label: "Roboto", value: "Roboto, sans-serif" },
                                { label: "Atkinson Hyperlegible", value: "'Atkinson Hyperlegible', sans-serif" },
                                { label: "Lora", value: "Lora, serif" },
                                { label: "Montserrat", value: "Montserrat, sans-serif" },
                                { label: "Merriweather", value: "Merriweather, serif" },
                            ]}
                        />
                        <MenuDivider />

                        <MenuButtonBold tooltipLabel={translate('textEditor.bold')} />
                        <MenuButtonItalic tooltipLabel={translate('textEditor.italic')} />
                        <MenuButtonUnderline tooltipLabel={translate('textEditor.underline')} />
                        <MenuButtonStrikethrough tooltipLabel={translate('textEditor.strikethrough')} />

                        <MenuDivider />

                        <MenuButtonOrderedList tooltipLabel={translate('textEditor.orderedList')} />
                        <MenuButtonBulletedList tooltipLabel={translate('textEditor.bulletedList')} />

                        <MenuDivider />

                        <MenuButtonAlignLeft tooltipLabel={translate('textEditor.alignLeft')} />
                        <MenuButtonAlignCenter tooltipLabel={translate('textEditor.alignCenter')} />
                        <MenuButtonAlignRight tooltipLabel={translate('textEditor.alignRight')} />
                        <MenuButtonAlignJustify tooltipLabel={translate('textEditor.justify')} />

                        {/* Add more controls of your choosing here */}
                    </MenuControlsContainer>
                )}
            />
            {error &&
                <Typography
                    variant="caption"
                    sx={{ p: 2 }}
                    color='error'
                >
                    {errorHelperText}
                </Typography>}
        </Box>
    );
}