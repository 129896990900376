import { Crop } from "react-image-crop";

export type UploadTypes = "Image" | "Video" | "Audio" | "Attach" | "Bundle";

export type EntityType = "ReturnRequest" | "Shop";

export type UploadParams = {
    entityId: string,
    fileName: string,
    entityType: EntityType,
    mediaType: UploadTypes
}

export type ConfirmUpload = {
    entityType: EntityType,
    entityId: string,
    fileName: string,
    section: string | null,
    mediaType: UploadTypes,
    uploadUrl: string
}

export type UploadUrl = {
    uploadUrl: string
}

//------ USED IN IMAGE EDITOR

export interface FileWithPreview extends File {
    preview: string
}

export type ImageInModalType = {
    preview: string,
    crop?: Crop,
    rotation?: number,
    scale?: number,
    aspect: number
}

export const DEFAULT_IMAGE_IN_MODAL: ImageInModalType = {
    preview: "",
    rotation: 0,
    scale: 1,
    aspect: 16 / 9
};