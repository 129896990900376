import { Receipt, Newspaper, DeleteForever, InsertDriveFile, Settings, UploadFileRounded, Search, Add } from "@mui/icons-material";
import { Typography, Tooltip, Card, Modal, TextField, InputAdornment, TablePagination, FormControlLabel, Switch, Divider, Button, IconButton, Box } from "@mui/material";
import { Container, Stack } from "@mui/system";
import { GridSortModel, GridColDef, DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { debounce } from "lodash";
import { useState, useRef, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { ItemSearchResult, ItemFilters, WarrantyApprove, Request } from "src/@types/request";
import { CurrencyTypes } from "src/@types/vehicle";
import DateZone from "src/appComponents/DateZone";
import useTable from "src/appHooks/useTable";
import { FileWithSection } from "src/appUtils/UploadHandler";
import { noData } from "src/components/empty-content/EmptyContent";
import { FortyDaysAlert } from "src/components/request-utils/FeesAndNotes";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { requestOperations } from "src/redux/request";
import { sparePartsOperations } from "src/redux/spareParts";
import { RootState, dispatch } from "src/redux/store";
import { DataGridStyle } from "src/utils/DataGridStyle";
import IncrementalInput from "src/utils/IncrementalInput";

const WarrantyDetailStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    p: 2,
    '& .MuiInputLabel-asterisk': { color: 'error.main' }
};

interface WarrantyFormProps {
    request: Request,
    onChange: (v: any) => void,
    changeFile: (f: FileWithSection[]) => void
}

export default function WarrantyForm({ request, onChange, changeFile }: WarrantyFormProps) {

    const { translate } = useLocales();

    const [file, setFile] = useState<FileWithSection[]>([]);

    const [searchModal, setSearchModal] = useState(false);

    const [bigAndHeavy, setBigAndHeavy] = useState(false);

    const [startingItem, setStartingItem] = useState<(ItemSearchResult & { qty: number })>();

    useEffect(() => {
        dispatch(sparePartsOperations.searchVendorSpareParts({
            pageIndex: 0,
            pageSize: 5,
            externalId: request.items[0].product.externalId
        })).unwrap().then(list => {
            const firstItem = {
                id: list.items[0].id,
                createdOn: new Date(),
                orderId: "",
                itemId: list.items[0].id,
                itemExternalId: list.items[0].externalId,
                name: list.items[0].name,
                type: "SparePart",
                priority: "Low",
                quantity: list.items[0].stockQuantity,
                itemIndex: 1, //-------
                netAmount: {
                    withoutVat: 0,
                    currentCurrency: 'EUR' as CurrencyTypes
                },
                family: {
                    code: Number(list.items[0].family.code),
                    name: list.items[0].family.name
                },
                category: {
                    code: Number(list.items[0].category.code),
                    name: list.items[0].category.name
                },
                class: {
                    code: Number(list.items[0].class.code),
                    name: list.items[0].class.name
                },
                invoiceId: "",
                invoiceExternalId: "",
                deliveryNoteId: "",
                deliveryNoteExternalId: "",
                qty: request.items[0].requiredQuantity
            } as ItemSearchResult & { qty: number };

            setDisplay([firstItem]);

            setStartingItem(firstItem);
        });
    }, []);

    const [display, setDisplay] = useState<(ItemSearchResult & { qty: number })[]>([]);

    const [selected, setSelected] = useState<(ItemSearchResult & { qty: number })[]>(display);

    const { requestItems, isItemsLoading } = useSelector((state: RootState) => state.request);

    const [searchVal, setSearchVal] = useState("");

    const debounced = useRef(
        debounce((options: ItemFilters) => {
            dispatch(requestOperations.searchVendorReturnItems(options));
        }, 500));

    const handleSearch = (val: string) => {
        setSearchVal(val);
        if (val.length >= 3 || val.length === 0)
            debounced.current({ ...options, itemName: val });
    };

    const [warrantyObj, setWarrantyObj] = useState<WarrantyApprove>({ invoiceDate: "", invoice: "", approvedProducts: [] });

    const {
        rowsPerPage,
        page,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
        dense,
        order,
        orderBy,
        setOrder,
        setOrderBy
    } = useTable();

    const handleDrop = (file: File, name: string) => {
        setFile([Object.assign(file, { section: name })]);
    };

    const handleDelete = (val: String) => { };

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const handleQuantity = (ind: number, val: number) => {
        setDisplay((prev) => {
            let arr = [...prev];
            arr[ind].qty =
                val > 0 ? val : arr[ind].qty;

            return arr;
        });
    };

    const [options, setOptions] = useState<ItemFilters>({
        pageIndex: page,
        pageSize: rowsPerPage,
        sortAscending: order === 'desc' ? true : false,
        sortField: orderBy || undefined,
        requestType: "Warranty",
        customerId: request.customer.id
    });

    useEffect(() => {
        if (searchModal)
            dispatch(requestOperations.searchVendorReturnItems(options));
    }, [options, searchModal]);

    useEffect(() => {
        if (searchModal)
            setSelected(display);

        setSearchVal("");
    }, [searchModal]);

    useEffect(() => {
        setOptions({
            ...options,
            pageIndex: page,
            pageSize: rowsPerPage,
            sortAscending: order === 'desc' ? true : false,
            sortField: orderBy || undefined,
            itemName: searchVal || undefined,
        });
    }, [order, orderBy, page, rowsPerPage]);

    useEffect(() => {
        if (requestItems.totalCount <= rowsPerPage * page)
            onChangePage(null, 0);
    }, [onChangePage, page, rowsPerPage, requestItems.totalCount]);

    const columns: GridColDef<ItemSearchResult>[] = [
        {
            field: 'itemExternalId',

            headerName: `${translate('request.form.partN')}`,
            flex: 0.5,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: 0.6,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        ...(request.requestType !== "WrongShipment" ? [{
            field: 'quantity',
            headerName: `${translate('request.form.avQuantity')}`,
            flex: 0.6,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        }] : []),
        {
            field: 'family',
            headerName: `${translate('spareParts.detail.family')}`,
            flex: 0.9,
            renderCell: (params) =>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                    <Typography variant="subtitle2"><b>{params.row.family ? params.row.family.code : "—"}</b></Typography>
                    <Tooltip title={params.row.family.name}>
                        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                            {params.row.family ? params.row.family.name : "—"}
                        </Typography>
                    </Tooltip>

                </Box>

        },
        {
            field: 'Invoice',
            headerName: `${translate('orders.tableHeaders.invoice')}`,
            flex: 0.5,
            renderCell: (p) => {
                if (p.row.invoiceExternalId)
                    return <Typography variant="body2">{p.row.invoiceExternalId}</Typography>;
                else if (p.row.invoiceId)
                    return <Receipt />;

                return <Typography variant="body2">{"—"}</Typography>;
            }
        },
        {
            field: 'DeliveryNote',
            headerName: `${translate('orders.tableHeaders.deliveryNote')}`,
            flex: 0.5,
            renderCell: (p) => {
                if (p.row.deliveryNoteExternalId)
                    return <Typography variant="body2">{p.row.deliveryNoteExternalId}</Typography>;
                else if (p.row.deliveryNoteId)
                    return <Newspaper />;

                return <Typography variant="body2">{"—"}</Typography>;
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: 0.5,
            renderCell: (params: any) => {
                if (!params.value) {
                    return '—';
                }

                return <DateZone variant="body2" date={params.value} noSeconds shortMonth />;
            },
        }
    ];

    useEffect(() => onChange(warrantyObj), [warrantyObj]);

    useEffect(() => changeFile(file), [file]);

    useEffect(() => {

        setWarrantyObj(prev => ({
            ...prev,
            approvedProducts: display.map(v => ({
                approvedQuantity: v.qty,
                productId: v.itemId,
                bigAndHeavy: bigAndHeavy,
            }))
        }));

    }, [display, bigAndHeavy]);

    const isDesktop = useResponsive("up", "md");

    const showFortyAlert = useMemo(() => {

        if (warrantyObj.invoiceDate) {

            const warrantyDate = new Date(warrantyObj.invoiceDate);

            const today = new Date();

            const diffTime = today.getTime() - warrantyDate.getTime();

            const diffDays = Math.round(diffTime / (1000 * 3600 * 24));

            return diffDays > 40;
        }

        return false;
    }, [warrantyObj.invoiceDate]);

    return (
        <Box sx={{ ...WarrantyDetailStyle, mt: 2 }}>

            <Modal
                open={searchModal}
                onClose={() => {
                    setSearchModal(false);
                    onChangePage(null, 0);
                }}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{ m: 'auto' }}
            >
                <Container
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >

                    <Card
                        sx={{
                            p: 2,
                            pb: 0,
                            maxHeight: '95vh',
                            overflowY: "auto"
                        }}
                    >

                        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
                            <TextField
                                value={searchVal}
                                onChange={(e) => handleSearch(e.target.value)}
                                label={`${translate('commons.search')}`}
                                placeholder={`${translate('commons.search')}...`}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <DataGrid
                                columns={columns}
                                rows={requestItems.items}
                                disableColumnMenu
                                disableRowSelectionOnClick
                                keepNonExistentRowsSelected
                                disableColumnResize
                                checkboxSelection
                                rowSelectionModel={selected.map(i => i.id)}
                                onCellClick={(row) => {
                                    if (selected.find(v => v.id === row.row.id))
                                        setSelected(prev => prev.filter(v => v.id !== row.row.id));
                                    else if (selected.length < 10)
                                        setSelected(prev => prev.concat([{ ...row.row, qty: 0 }]));

                                }}
                                pagination
                                paginationModel={{
                                    page: page,
                                    pageSize: rowsPerPage
                                }}
                                density={dense ? 'compact' : 'standard'}
                                sortingMode={"server"}
                                onSortModelChange={handleSort}
                                loading={isItemsLoading}
                                sx={{ ...DataGridStyle, maxHeight: 640, minHeight: 300 }}
                                pageSizeOptions={[5, 10, 15, 30]}
                                slots={{
                                    noRowsOverlay: noData,
                                    footer: () => (
                                        <Box sx={{
                                            position: 'relative',
                                            width: { xs: "85vw", sm: "90vw", md: "auto" },
                                        }}>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 15, 30]}
                                                component="div"
                                                count={requestItems.totalCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={onChangePage}
                                                onRowsPerPageChange={onChangeRowsPerPage}
                                                labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                                sx={{
                                                    overflow: "hidden",
                                                    "& .MuiTablePagination-toolbar": { px: 0 },
                                                    "& .MuiTablePagination-actions": { m: "inherit" }
                                                }}
                                            />

                                            <FormControlLabel
                                                control={<Switch checked={dense} onChange={onChangeDense} />}
                                                label={`${translate('commons.dense')}`}
                                                sx={{
                                                    px: { xs: 0, sm: 2 },
                                                    py: { xs: 0, sm: 1.5 },
                                                    pb: { xs: 1.5, sm: 0 },
                                                    mx: 0, top: 0,
                                                    justifyContent: "center",
                                                    width: { xs: "80vw", sm: "auto" },
                                                    position: { sm: 'absolute' }
                                                }}
                                            />
                                        </Box>
                                    )
                                }}
                            />
                        </Box>

                        <Divider />

                        <Box
                            sx={{
                                justifyContent: 'space-between',
                                ml: 'auto', display: 'flex',
                                gap: 1, p: 3
                            }}
                        >

                            <Button
                                variant="soft"
                                color="inherit"
                                onClick={() => {
                                    setSearchModal(false);
                                    onChangePage(null, 0);
                                }}
                            >
                                {`${translate("commons.cancel")}`}
                            </Button>

                            <Box sx={{ display: 'flex', gap: 2 }}>

                                <Button startIcon={<DeleteForever />} variant='contained' color='error' onClick={() => setSelected([])}>
                                    {`${translate('rules.form.clear')}`}
                                </Button>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    onClick={() => {
                                        setDisplay(selected);
                                        setSearchModal(false);
                                        onChangePage(null, 0);
                                    }}
                                >
                                    {`${translate("commons.add")}`}
                                </Button>

                            </Box>

                        </Box>
                    </Card>
                </Container>
            </Modal>

            <Divider />

            <Box sx={{ py: 1 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {`${translate('request.form.attachDoc')}`}
                </Typography>

                {file.length === 0 ? (
                    <Drop inputName="madd" onDrop={(files) => handleDrop(files[0], 'maddDoc')} />
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            background: '#F4F6F8',
                            p: 2,
                            borderRadius: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: 1, alignContent: 'center' }}>
                            <InsertDriveFile />
                            <Typography variant="subtitle1" sx={{ mr: 'auto' }}>
                                {file[0]?.name}
                            </Typography>
                        </Box>
                        <IconButton
                            sx={{ ml: 'auto', color: 'inherit' }}
                            onClick={() => handleDelete('madd')}
                        >
                            <DeleteForever color="inherit" />
                        </IconButton>
                    </Box>
                )}

                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, mt: 2 }}>
                    <TextField
                        required
                        value={warrantyObj.invoice}
                        label={`${translate('request.form.maddNum')}`}
                        onChange={(e) => setWarrantyObj(prev => ({ ...prev, invoice: e.target.value }))}
                    />

                    <DatePicker
                        disableFuture
                        label={`${translate('request.form.maddDate')}`}
                        views={['year', 'month', 'day']}
                        format={'dd/MM/yyyy'}
                        value={warrantyObj.invoiceDate ? new Date(warrantyObj.invoiceDate) : null}
                        onAccept={(value) => {
                            if (value)
                                setWarrantyObj(prev => ({ ...prev, invoiceDate: format(value, 'yyyy-MM-dd') }));
                        }}
                        onChange={(fromDateValue, inputValue) => {
                            if (!inputValue.validationError && fromDateValue) {
                                setWarrantyObj(prev => ({ ...prev, invoiceDate: format(fromDateValue, 'yyyy-MM-dd') }));

                            }
                        }}
                        slotProps={{ textField: { required: true } }}
                    />
                </Box>
            </Box>

            {showFortyAlert && <FortyDaysAlert />}

            <Divider />

            <Box>
                {display.length === 0 &&
                    <Box
                        sx={{
                            display: 'flex', gap: 1,
                            flexDirection: 'column',
                            py: 1
                        }}
                    >
                        <Typography variant="h6">
                            {`${translate('request.form.searchMadd')}`}
                        </Typography>

                        <Box sx={{ width: "100%", mt: 1.5 }}>
                            <Button
                                variant="soft"
                                size={"large"}
                                fullWidth
                                sx={{ height: "100%", width: "100%", py: 3.5 }}
                                startIcon={<Add />}
                                onClick={() => setSearchModal(true)}
                            >
                                {`${translate("rules.form.edit.addPartRef")}`}
                            </Button>
                        </Box>

                    </Box>
                }

                {display.length > 0 &&
                    <Box>
                        <Box
                            sx={{
                                display: 'flex', mt: 1,
                                flexDirection: isDesktop ? 'row' : 'column'
                            }}
                        >

                            <Typography variant="h6" sx={{ mb: 2 }}>
                                {`${translate('request.form.searchMadd')}`}
                            </Typography>
                            <Button
                                variant="text"
                                size={"medium"}
                                onClick={() => setSearchModal(true)}
                                startIcon={<Add />}
                                sx={{ ml: "auto", height: "auto", mt: (isDesktop ? 0 : 1.5) }}
                            >
                                {`${translate("rules.form.edit.addPartRef")}`}
                            </Button>

                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: isDesktop ? 0 : 2,
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                maxHeight: 500
                            }}
                        >
                            {display.map((p, ind) => (
                                <Box key={ind}
                                    sx={{
                                        my: 1.5,
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            mr: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2
                                        }}
                                    >
                                        <Settings />
                                        <Typography>
                                            {p.itemExternalId ? p.itemExternalId + ' - ' : ''}
                                            {p.name}
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            mr: 3,
                                            ml: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                            justifyContent: 'end',
                                        }}
                                    >
                                        <Typography
                                            color={'text.secondary'}
                                            variant="caption"
                                            sx={{ pr: 2 }}
                                        >
                                            {`${translate('request.form.qtyReceived')}`}
                                        </Typography>

                                        <IncrementalInput
                                            onChange={(_, val) => handleQuantity(ind, +val)}
                                            defaultValue={p.qty}
                                        />

                                        <IconButton
                                            onClick={() => setDisplay((prev) => prev.filter(v => v.id !== p.id))}
                                            sx={{ visibility: ((startingItem && p.id !== startingItem.id) ? "visible" : "hidden") }}
                                        >
                                            <DeleteForever sx={{ color: 'text.primary' }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                }

                <Divider sx={{ my: 2 }} />

                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6">Request is partially completed?</Typography>
                    <Switch checked={incomplete} onChange={(e) => setIncomplete(e.target.checked)} />
                </Box>

                {incomplete &&
                    <TextField
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        sx={{ my: 2 }}
                        multiline
                        rows={2}
                        fullWidth
                        variant="outlined"
                        placeholder={`${translate(`request.insertNote`)}`}
                    />
                } */}
            </Box>

            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: '#E2E2E2',
                }}
            >
                <Stack sx={{ m: 2, mr: 'auto' }}>
                    <Typography variant="h6">{`${translate('request.big')}`}</Typography>
                    <Typography variant="body2">{`${translate('request.messages.bigDesc')}`}</Typography>
                </Stack>

                <Box sx={{ m: 2, ml: 'auto' }}>
                    <Switch
                        checked={bigAndHeavy}
                        name="bigAndHeavy"
                        onChange={(e) => setBigAndHeavy(e.target.checked)}
                    />
                </Box>
            </Box>

        </Box>
    );
}

export function Drop(props: { inputName: string; onDrop: (file: File[]) => void, label?: string }) {

    const { translate } = useLocales();

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            'image/*': ['.jpg', '.jpeg', '.png']
        },
        maxSize: 5000000,
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            props.onDrop(
                acceptedFiles
            );
        },
    });

    return (
        <div
            {...getRootProps({
                style: {
                    display: 'flex',
                    padding: '20px',
                    height: '150px',
                    width: '100%',
                    borderRadius: '8px',
                    background: '#F4F6F8',
                    color: '#6D6D6D',
                    outline: 'none',
                    cursor: 'pointer',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            })}
        >
            <input {...getInputProps()} name={props.inputName} />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <UploadFileRounded sx={{ mb: 1 }} />
                <Typography color="inherit" variant="caption">
                    {' '}
                    {props.label ? props.label : `${translate('request.form.uploadFile')}`}
                </Typography>
            </Box>
        </div>
    );
}
