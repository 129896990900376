import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs, Card, Box, FormControlLabel, Switch, Divider, Typography } from '@mui/material';
import { dispatch } from 'src/redux/store';
import useLocales from 'src/appHooks/useLocales';
import Label from 'src/components/label';
import useTable from 'src/appHooks/useTable';
import { useForm } from 'react-hook-form';
import { GridCellParams, GridColDef, GridColumnVisibilityModel, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import FormProvider from 'src/components/hook-form';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import { INFINITE_SCROLL_GENERIC_LIST_FILTERS, InfiniteScrollGenericListFilters, InfiniteScrollPagedResponse, SequenceToken, ToolbarSearchFilters } from 'src/@types/commons';
import { cloneDeep, isArray, isEqual, isObject, omitBy, remove } from 'lodash';
import useResponsive from 'src/hooks/useResponsive';
import useLocalStorage from 'src/hooks/useLocalStorage';
import GenericFilterSidebar from 'src/utils/list/sidebar/GenericFilterSidebar';
import { FilterListType, QuickFilters, ViewTypes } from 'src/@types/list';
import GenericFilterSummary from 'src/utils/list/summary/GenericFilterSummary';
import GenericFilterToolbar from 'src/utils/list/toolbar/GenericFilterToolbar';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { DataGridPro } from '@mui/x-data-grid-pro';
import VisibilityModelComponent from './toolbar/VisibilityModelComponent';
import ViewSwitchComponent from './toolbar/ViewSwitchComponent';

type NewGenericListProps<T extends GridValidRowModel, Q extends InfiniteScrollGenericListFilters> = {
    list: T[],
    resetList: VoidFunction,
    isLoading: boolean,
    totalCount: number,
    defaultFilters: Q,
    specificStatsKeysToDelete?: string[],
    specificFullKeysToDelete?: string[],
    quickFilters?: QuickFilters[],
    renderQuickFilters?: (key: string) => any,
    toolbarFiltersList?: ToolbarSearchFilters[],
    filtersInSidebar?: FilterListType[],
    updateCheckField: (field: string, filters: Q) => boolean,
    extraSearchFiltersChecks?: (searchFilters: Q) => Q,
    context: string,
    datagridColumns: GridColDef<T>[],
    setActualRow: Dispatch<any>,
    handleCellClick: (params: GridCellParams<any>) => void,
    setFiltersCallback?: Dispatch<SetStateAction<Q>>,
    filterStatus?: string,
    onChangeFilterStatus?: (event: React.SyntheticEvent<Element, Event> | null, newValue: string) => void,
    search?: (options: { filters: Q, check: boolean }) => AsyncThunkAction<InfiniteScrollPagedResponse<(T & SequenceToken)>, any, any>,
    customSearchFunc?: (options: { filters: Q, check: boolean }) => void,
    searchStatistics?: (filters: Q) => AsyncThunkAction<any, any, any>,
    filtersInUrl?: string,
    setFiltersInUrl?: any,
    listDescription: string,
    showDates?: boolean,
    datesNames?: [string, string],
    dateSearchBy?: [string, string],
    multipleView?: boolean
}

export default function InfiniteScrollGenericList<T extends GridValidRowModel, Q extends InfiniteScrollGenericListFilters>({
    list,
    resetList,
    isLoading,
    totalCount,
    defaultFilters,
    specificStatsKeysToDelete = [],
    specificFullKeysToDelete = [],
    quickFilters,
    renderQuickFilters,
    toolbarFiltersList,
    filtersInSidebar,
    datagridColumns,
    updateCheckField,
    extraSearchFiltersChecks,
    context,
    setActualRow,
    handleCellClick,
    setFiltersCallback,
    filterStatus,
    onChangeFilterStatus,
    search,
    searchStatistics,
    customSearchFunc,
    filtersInUrl,
    setFiltersInUrl,
    listDescription,
    showDates,
    datesNames,
    dateSearchBy,
    multipleView
}: NewGenericListProps<T, Q>) {

    const {
        order,
        setOrderBy,
        orderBy,
        setOrder,
        dense,
        onChangeDense
    } = useTable({ defaultOrder: "desc" });

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { translate, currentLang } = useLocales();

    const [filters, setFilters] = useState(defaultFilters);

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    const [openSidebar, setOpenSidebar] = useState(false);

    const [resetForm, setResetForm] = useState(true);

    const [resetFormElement, setResetFormElement] = useState("");

    const [showSummary, setShowSummary] = useState(true);

    const methods = useForm<InfiniteScrollGenericListFilters>({ defaultValues: defaultFilters });

    const { reset, getValues, watch, setValue } = methods;

    var formValues = watch();

    //---- IS DEFAULT - START ----//
    // Checks if there are some filters selected
    const statsKeysToDelete: string[] = useMemo(() => ["sortField", "sortDirection", "size", "sequenceToken", "pagination", ...specificStatsKeysToDelete], [specificStatsKeysToDelete]);

    const fullKeysToDelete: string[] = useMemo(() => ["all", ...specificFullKeysToDelete], [specificFullKeysToDelete]);

    const statsKeyRemover = useCallback((key: string) => {
        return !(statsKeysToDelete.includes(key));
    }, [statsKeysToDelete]);

    const fullKeyRemover = useCallback((key: string) => {
        return !(statsKeysToDelete.includes(key) || fullKeysToDelete.includes(key));
    }, [fullKeysToDelete, statsKeysToDelete]);

    const isDefault = useCallback((filter: InfiniteScrollGenericListFilters, controller?: InfiniteScrollGenericListFilters, forStats?: boolean) => {

        const checkKeys = controller ? (Object.keys(controller).length > Object.keys(filter).length ? controller : filter) : defaultFilters;

        const checkObject = controller ? controller : defaultFilters;

        const found = remove(Object.keys(checkKeys), forStats ? statsKeyRemover : fullKeyRemover)
            .find((element) => {
                if ((isArray(filter[element]) && isArray(checkObject[element])) || (isObject(filter[element]) && isObject(checkObject[element]))) {
                    return !isEqual(filter[element], checkObject[element]);
                } else {
                    return filter[element] !== checkObject[element];
                }
            });

        return (!found);
    }, [defaultFilters, statsKeyRemover, fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    //---- CLOSE AND OPEN SIDEBAR - START ----//
    const handleOpenSidebar = () => {
        setOpenSidebar(true);
    };

    const handleCloseSidebar = useCallback(() => {
        if (resetForm) {
            reset(defaultFilters);
        };
        setOpenSidebar(false);
    }, [defaultFilters, reset, resetForm]);
    //---- CLOSE AND OPEN SIDEBAR - START ----//

    //---- CLEAR FROM SUMMARY FUNC ----//
    const handleClearFromSummary = useCallback((section: string) => {
        setResetFormElement(section);
        if (isDefault(formValues)) {
            setResetForm(true);
        }
    }, [formValues, isDefault]);

    //---- FILTERS IN URL GET/SET - START ----//
    const location = useLocation();

    const [firstRender, setFirstRender] = useState(true);

    const [lastStatsFilters, setLastStatsFilters] = useState<any>(INFINITE_SCROLL_GENERIC_LIST_FILTERS);

    const updateFiltersInUrl = useCallback((filters: any) => {

        let queryString = Object.keys(filters).filter((field) => updateCheckField(field, filters))
            .map((key) => {
                if (isArray(filters[key]) || isObject(filters[key]))
                    return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(filters[key]))}`;

                return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
            })
            .join('&');

        if (queryString) queryString = "#" + queryString;

        if (setFiltersInUrl) dispatch(setFiltersInUrl(queryString));

        navigate(location.pathname + queryString, { replace: true });

    }, [location, navigate, updateCheckField, setFiltersInUrl]);

    const getFiltersFromUrl = useMemo(() => {

        const { hash } = location;

        const cleanedHash = filtersInUrl?.slice(1) ?? "";

        const decodedQuery = decodeURIComponent(cleanedHash);

        const searchParams = new URLSearchParams(decodedQuery);

        let searchFilters: InfiniteScrollGenericListFilters = {
            pagination: "After",
            sequenceToken: null,
            size: Number(searchParams.get('size') ?? INFINITE_SCROLL_GENERIC_LIST_FILTERS.size),
            sortField: orderBy || INFINITE_SCROLL_GENERIC_LIST_FILTERS.sortField,
            sortDirection: order === 'desc' ? "Descending" : "Ascending",
            status: filterStatus || undefined
        };

        if (hash) {

            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            searchFilters = {
                ...(Object.fromEntries(searchParams.entries())),
                ...searchFilters
            };

            if (filtersInSidebar) filtersInSidebar.filter((field) => field.toParse).forEach((field) => searchFilters[field.name] = JSON.parse(searchFilters[field.name]));

            if (searchParams.get('customFields')) searchFilters["customFields"] = JSON.parse(searchParams.get('customFields')!);

        }

        searchFilters = omitBy(searchFilters, (x) => x === undefined || x === null) as InfiniteScrollGenericListFilters;

        return searchFilters as Q;

    }, [location, filtersInUrl, orderBy, order, filterStatus, filtersInSidebar]);
    //---- FILTERS IN URL GET/SET - END ----//

    //---- FETCH DATA FUNC ----//
    // Gets all filter values ​​other than the default ones and puts them in the url
    const fetchData = useCallback(async (values: Q) => {

        var searchFilters: any = {};

        if (isEqual(values, defaultFilters)) {
            searchFilters = INFINITE_SCROLL_GENERIC_LIST_FILTERS;
        } else {
            Object.keys(values).forEach((field) => { searchFilters[field] = !isEqual(values[field], defaultFilters[field]) ? values[field] : null; });

            searchFilters = {
                ...searchFilters,
                size: INFINITE_SCROLL_GENERIC_LIST_FILTERS.size,
                sortField: orderBy,
                sortDirection: order === 'desc' ? "Descending" : "Ascending",
                pagination: "After",
                sequenceToken: null
            };
        }

        updateFiltersInUrl(searchFilters);
    }, [defaultFilters, order, orderBy, updateFiltersInUrl]);

    //---- SEARCH FOR ITEMS AND STATISTICS - START ----//
    const adjustLastFiltered = useCallback((values: Q) => {

        const result = Object.entries(values).reduce((acc, [key, value]) => {

            if (!isEqual(value, defaultFilters[key as keyof Q])) acc[key as keyof Q] = value;

            return acc;
        }, {} as Partial<Q>);

        return {
            ...result,
            ...INFINITE_SCROLL_GENERIC_LIST_FILTERS
        };
    }, [defaultFilters]);

    const onSearch = useCallback((filtersFromUrl: Q) => {

        if (isLoading) return;

        const isInfiniteScrolling = isEqual(filtersFromUrl, filters);

        if (!isInfiniteScrolling) {
            setFilters(filtersFromUrl);
            updateFiltersInUrl(filtersFromUrl);
        }

        if (setFiltersCallback) setFiltersCallback(filtersFromUrl);

        const customFieldsFromUrl: Record<string, string> = Object.entries(filtersFromUrl.customFields || {})
            .map(([k, val]) => ({
                key: "customFields." + k,
                value: val
            }))
            .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

        const updatedFiltersFromUrl = { ...filtersFromUrl, ...customFieldsFromUrl };

        delete updatedFiltersFromUrl.customFields;

        const searchOptions = { filters: { ...updatedFiltersFromUrl, sequenceToken: isInfiniteScrolling ? list[list.length - 1].searchSequenceToken : null }, check: isInfiniteScrolling };

        if (customSearchFunc) customSearchFunc(searchOptions);
        else if (search) dispatch(search(searchOptions));

        if (searchStatistics && !isInfiniteScrolling &&
            (firstRender
                || isEqual(filtersFromUrl, omitBy(INFINITE_SCROLL_GENERIC_LIST_FILTERS, (x) => x === undefined || x === null))
                || !isDefault(filtersFromUrl, lastStatsFilters as Q, true)
            )
        ) {
            dispatch(searchStatistics(updatedFiltersFromUrl));
            setLastStatsFilters(adjustLastFiltered(filtersFromUrl));
        }

        if (firstRender) setFirstRender(false);

    }, [isLoading, filters, setFiltersCallback, customSearchFunc, list, search, firstRender, isDefault,
        lastStatsFilters, searchStatistics, updateFiltersInUrl, adjustLastFiltered]);
    //---- SEARCH FOR ITEMS AND STATISTICS - END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - START ----//
    // This hook is used to call onSearch when filters or language are changed
    const isOrderDiff = useCallback((filtersToCheck: Q, olderFilters: Q) => {

        const differencesToOld = Object.keys(filtersToCheck).filter((filter) => filtersToCheck[filter] !== olderFilters[filter]);

        if (differencesToOld.includes("sortField") || differencesToOld.includes("sortDirection")) return true;

        const differencesToFilters = Object.keys(olderFilters).filter((filter) => olderFilters[filter] !== filtersToCheck[filter]);

        return differencesToFilters.includes("sortField") || differencesToFilters.includes("sortDirection");

    }, []);

    useEffect(() => {

        let searchFilters = cloneDeep(getFiltersFromUrl);

        if (extraSearchFiltersChecks) searchFilters = extraSearchFiltersChecks(searchFilters);

        if (!isEqual(searchFilters, filters) || lastUsedLang !== currentLang.label) {

            //if (!firstRender && isOrderDiff(searchFilters, filters)) searchFilters.pageIndex = 0;

            onSearch(searchFilters as Q);

            if (lastUsedLang !== currentLang.label) setLastUsedLang(currentLang.label);
        }

    }, [location, orderBy, order, firstRender, filterStatus, currentLang, lastUsedLang,
        filters, isOrderDiff, extraSearchFiltersChecks, onSearch, getFiltersFromUrl]);
    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

    //---- FILTERS SEARCH FUNC ----//
    // Used for search buttons in filters
    const handleSearchFilters = useCallback(() => {
        fetchData(getValues() as Q);
        setResetForm(false);
        setOpenSidebar(false);
        resetList();
    }, [fetchData, getValues, resetList]);

    //---- FILTERS RESET - START ----//
    const handleResetAllFilter = useCallback(() => {
        if (openSidebar) {
            handleCloseSidebar();
        }
        resetList();
        setResetForm(true);
        setShowSummary(false);
        fetchData(defaultFilters);
        reset(defaultFilters);
    }, [defaultFilters, fetchData, handleCloseSidebar, openSidebar, reset, resetList]);

    const handleResetSingleFilter = useCallback((fieldName: string, value?: any) => {
        if (isArray(formValues[fieldName])) {
            const index = formValues[fieldName].indexOf(value);

            if (index > -1) {
                formValues[fieldName].splice(index, 1);
                setValue(fieldName, formValues[fieldName]);
                handleClearFromSummary(fieldName);
                fetchData(formValues as Q);
            }
        } else if (isObject(formValues[fieldName])) {

            delete (formValues[fieldName] as Record<string, string>)[value];

            setValue(fieldName, formValues[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues as Q);

        } else {
            formValues[fieldName] = defaultFilters[fieldName];
            setValue(fieldName, defaultFilters[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues as Q);
        }
    }, [defaultFilters, fetchData, formValues, handleClearFromSummary, setValue]);
    //---- FILTERS RESET - END ----//

    //---- HANDLE TABLE - START ----//
    const getHeight = useCallback(() => {
        let height: string | number = "auto";

        if (!dense || list.length === 0) {
            height = isDesktop ? 633 : 649;
        }

        return height;
    }, [dense, isDesktop, list]);

    const getMaxHeight = useCallback(() => {
        return isDesktop ? 633 : 649;
    }, [isDesktop]);

    const handleSort = useCallback((sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy(INFINITE_SCROLL_GENERIC_LIST_FILTERS.sortField);
            setOrder("desc");
        }
    }, [setOrder, setOrderBy]);
    //---- HANDLE TABLE - END ----//

    //---- HANDLE COLUMN VISIBILITY - START -----//
    const [columns, setColumns] = useLocalStorage<Record<string, GridColumnVisibilityModel>>("columns", {});

    const [visibility, setVisibility] = useState<GridColumnVisibilityModel>(columns[context] || (() => datagridColumns.reduce((prev, curr) => {
        Object.assign(prev, { [curr.field]: true });

        return prev;
    }, {})));

    useEffect(() => {
        setColumns({ ...columns, [context]: visibility });
    }, [visibility]);
    //---- HANDLE COLUMN VISIBILITY - END -----//

    //---- HANDLE VIEW - START -----//
    const [localView, setLocalView] = useLocalStorage<Record<string, ViewTypes>>("view", {});

    const [listView, setListView] = useState<ViewTypes>(localView[context] || "List");

    useEffect(() => {
        setLocalView({ ...localView, [context]: listView });
    }, [listView]);
    //---- HANDLE VIEW - END -----//

    return (
        <Card>

            {(filtersInSidebar || listDescription) &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: { xs: 2, md: 3.5 }, py: 1.5 }}>
                    {listDescription &&
                        <Box>
                            <Typography variant="body2">
                                {listDescription}
                            </Typography>
                        </Box>
                    }

                    {filtersInSidebar &&
                        <FormProvider methods={methods}>
                            <Box sx={{ display: 'flex', alignItems: "center", gap: 1 }}>

                                {multipleView &&
                                    <ViewSwitchComponent
                                        view={listView}
                                        onChangeView={setListView}
                                    />
                                }

                                <VisibilityModelComponent
                                    columns={datagridColumns}
                                    model={visibility}
                                    onChangeModel={setVisibility}
                                />

                                <GenericFilterSidebar
                                    isOpen={openSidebar}
                                    onOpen={handleOpenSidebar}
                                    onClose={handleCloseSidebar}
                                    onFilter={handleSearchFilters}
                                    onResetAll={handleResetAllFilter}
                                    defaultFilters={defaultFilters}
                                    resetTrigger={resetForm}
                                    filterValues={{ ...formValues, ...(getFiltersFromUrl) }}
                                    filterList={filtersInSidebar}
                                    isDefault={isDefault}
                                    setShowSummary={setShowSummary}
                                    customfieldContext={[context]}
                                    resetFormElement={resetFormElement}
                                    setResetFormElement={setResetFormElement}
                                />
                            </Box>
                        </FormProvider>
                    }
                </Box>
            }

            {(quickFilters && renderQuickFilters) &&
                <>
                    <Tabs
                        allowScrollButtonsMobile
                        variant="scrollable"
                        scrollButtons={!isDesktop}
                        value={filterStatus}
                        onChange={(e, value) => {
                            //resetList();
                            if (onChangeFilterStatus) onChangeFilterStatus(e, value);
                        }}
                        sx={{
                            px: { xs: 0, md: 2 },
                            bgcolor: 'background.neutral'
                        }}
                    >
                        {quickFilters.map((tab) => (
                            <Tab
                                disableRipple
                                key={tab.key}
                                label={tab.label}
                                value={tab.key}
                                icon={
                                    <Label color={tab?.color} sx={{ mr: 1 }}>
                                        {renderQuickFilters(tab.key)}
                                    </Label>
                                }
                            />
                        ))}

                    </Tabs>

                    <Divider />
                </>
            }

            {(toolbarFiltersList || showDates) &&
                <FormProvider methods={methods}>
                    <GenericFilterToolbar
                        filterValues={{ ...formValues, ...getFiltersFromUrl }}
                        defaultFiltersValues={defaultFilters}
                        onSearch={handleSearchFilters}
                        onResetAll={handleResetAllFilter}
                        optionsFields={toolbarFiltersList}
                        showDates={showDates}
                        datesNames={datesNames}
                        dateSearchBy={dateSearchBy}
                        showSummary={showSummary}
                        setShowSummary={setShowSummary}
                        propFullKeysToRemove={statsKeysToDelete}
                    />
                </FormProvider>}

            {filtersInSidebar &&
                <GenericFilterSummary
                    showSummary={showSummary && !openSidebar && !isDefault({ ...formValues, ...(getFiltersFromUrl) })}
                    defaultFilters={defaultFilters}
                    filterValues={{ ...formValues, ...getFiltersFromUrl }}
                    filterList={filtersInSidebar}
                    onResetFilter={handleResetSingleFilter}
                    onResetAll={handleResetAllFilter}
                    customfieldContext={[context]}
                />
            }

            <Divider />

            <DataGridPro
                rowCount={100}
                rows={list}
                columns={datagridColumns}
                disableColumnResize
                density={(dense && list.length > 0) ? 'compact' : 'standard'}
                sortingMode={"server"}
                paginationMode={"server"}
                onSortModelChange={handleSort}
                loading={isLoading}
                columnVisibilityModel={visibility}
                slots={{
                    noRowsOverlay: noData,
                    footer: () => (
                        <>
                            {list.length === 0 && <Divider />}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    alignItems: "center",
                                    justifyContent: { xs: "normal", sm: "space-between" },
                                    height: { xs: "67px", sm: "56px" },
                                    minHeight: { xs: "67px", sm: "56px" },
                                    px: { xs: 2, sm: 4 },
                                    backgroundColor: "background.neutral"
                                }}
                            >
                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label={translate('commons.dense')}
                                />
                                <Typography variant="body2">
                                    {translate('commons.totalCount') + ": " + totalCount}
                                </Typography>
                            </Box>
                        </>
                    )
                }}
                disableColumnMenu
                onCellClick={(params) => {
                    setActualRow(params);

                    if (!window.getSelection()?.toString())
                        handleCellClick(params);
                }}
                scrollEndThreshold={20}
                onRowsScrollEnd={(params) => {
                    if (list.length < totalCount) {
                        onSearch(getFiltersFromUrl);
                    }
                }}
                sx={{
                    ...DataGridStyle,
                    cursor: 'pointer',
                    height: getHeight(),
                    maxHeight: getMaxHeight()
                }}
            />

        </Card>
    );
} 