import { Order, OrderDetailItem, OrderType, Priority } from "./orders";
import { Address, DEFAULT_PARENT, GenericOrganization, OganizationType, OrganizationSearchResult } from "./organizations";
import { CurrencyTypes } from "./vehicle";

//----- CLIENT -----//

export const RequestTypeArr = ["", "NewPart", "DamagedPart", "WrongShipment", "Core", "Quality", "Warranty"] as const;

export type RequestType = typeof RequestTypeArr[number];

export const GreenStatusesArr = ["Created", "Printed", "ReadyForPickUp", "PickedUp", "Arrived", "Accepted", "CollectedInContainer", "Paid", "DeltaToBeInvoiced"] as const;

export type GreenStatusType = typeof GreenStatusesArr[number];

export const RedStatusesArr = ["RefusedByBackoffice", "RefusedByWarehouse", "ReturnedToCustomer", "Deleted", "Claimed", "Lost", "RefusedAvailableForPrinting", "RefusedPrinted", "RefusedReadyForPickUp", "RefusedPickedUp", "LabelExpired", "LabelRestoreRequested", "ToBeAmended", "Closed"] as const;

export type RedStatusType = typeof RedStatusesArr[number];

export const YellowStatusesArr = ["WaitingManualValidation", "AvailableForPrinting", 'WaitingForInvestigation'] as const;

export type YellowStatusType = typeof YellowStatusesArr[number];

export type AllStatuses = GreenStatusType | RedStatusType | YellowStatusType;

export const CompletedStatusesArr = ["Accepted", "CollectedInContainer", "Paid", "DeltaToBeInvoiced"];

export const RefusedStatusesArr = ["RefusedByBackoffice", "WaitingForInvestigation", "RefusedByWarehouse", "ReturnedToCustomer", "Claimed", "Lost", "RefusedAvailableForPrinting", "RefusedPrinted", "RefusedReadyForPickUp", "RefusedPickedUp", "LabelExpired", "LabelRestoreRequested", "ToBeAmended", "Closed"];

export const UnderProcessingStatusesArr = ["WaitingManualValidation", "Validated", "Printed", "ReadyForPickUp", "PickedUp", "Arrived", "AvailableForPrinting"];

export type RequestReason = "Unknown" | "TooBig" | "TooSmall" | "Other" | "UnclearProductInformation" | "UnclearProductPicture" | "PartDeliveredTooLate" | "SomewhereElseCheaper" |
  "ReceivedDouble" | "DidNotUseThePart" | "ThePartDoesntWork" | "OriginalPackagingContainsWrongParts" | "PartOrderedIsNotComplete" | "SurplusDelivered" | "MissingParts";

export type ApprovedType = "BackInStock" | "ReturnToSupplier" | "Scrapping" | "ChangeReference" | "RepairFee" | "";

export type WarrantyApprove = {
  invoice: string,
  invoiceDate: string,
  approvedProducts: {
    productId: string,
    approvedQuantity: number,
    bigAndHeavy: boolean
  }[]
}

export type RequestFilters = {
  [key: string]: any
  all?: string,
  sortField?: string,
  sortAscending?: boolean,
  pageIndex: number,
  pageSize: number,
  status?: AllStatuses[] | null,
  from?: string,
  to?: string,
  externalId?: string,
  requestType?: RequestType | "All",
  customerId?: string,
  customer?: OrganizationSearchResult | GenericOrganization,
  customFields?: Record<string, string>,
  hasClaim?: boolean
};

export type ItemFilters = {
  [key: string]: any
  orderId?: string,
  documentExternalId?: string,
  skipRules?: boolean
  customerId?: string,
  vendorId?: string,
  requestType: string,
  itemType?: string
  itemName?: string
  itemExternalId?: string
  sortField?: string
  sortAscending?: boolean
  pageIndex: number,
  pageSize: number
  tags?: string[]
}

export type ItemSearchResult = {
  id: string,
  createdOn: Date,
  orderId: string,
  itemId: string,
  itemExternalId: string,
  name: string,
  type: OrderType,
  priority: Priority,
  quantity: number,
  itemIndex: number,
  netAmount: {
    withoutVat: number,
    currentCurrency: CurrencyTypes
  },
  unitNetAmount: {
    withoutVat: number,
    currentCurrency: CurrencyTypes,
    withoutVatInEuro: number,
    updatedOn: Date
  },
  unitGrossAmount: {
    withoutVat: number,
    currentCurrency: CurrencyTypes,
    withoutVatInEuro: number,
    updatedOn: Date
  },
  grossAmount: {
    withoutVat: number,
    currentCurrency: CurrencyTypes
  },
  family: {
    code: number,
    name: string
  },
  category: {
    code: number,
    name: string
  },
  class: {
    code: number,
    name: string
  },
  invoiceId: string,
  invoiceExternalId: string,
  deliveryNoteId: string,
  deliveryNoteExternalId: string,
  skippedRule: boolean
}

export type ApproveOptions = {
  id: string,
  note: string | null,
  approvedType: ApprovedType | null,
  customFields: Record<string, string>,
  returnFee: number,
  surveillanceFee: number,
  invoiceDataFee: number,
  approvedProducts: {
    productId: string,
    approvedQuantity: number,
    bigAndHeavy: boolean
  }[]
}

export type RejectOptions = {
  id: string,
  note: string | null,
  customFields: Record<string, string> | null
}

export type PrintOptions = {
  id: string,
  note: string,
  customFields: Record<string, string>
}

export type CloseAnomalyOptions = {
  id: string,
  note: string,
  customFields: Record<string, string>
}

export type ClaimOptions = {
  id: string,
  note?: string,
  customFields?: Record<string, string>
}

export type WarehouseAcceptOptions = {
  id: string,
  note: string,
  customFields: Record<string, string>
  partAccepted: boolean,
  partConditionAccepted: boolean,
  partConditionMedia: string[],
  reasonAccepted: boolean,
  administrativeCheckAccepted: boolean,
  containerCode: string | null,
  reasonCode: string | null
}

export type AlertOptions = {
  orderId: string,
  productId: string
}

export type Alert = {
  invoiceFee: number,
  frequencyFee: number,
  hasSurveillance: boolean
}

export type Tracking = {
  createdOn: Date,
  status: string
}

export type MediaFileInfo = {
  url: string,
  watermark: boolean,
  width: number,
  height: number,
  size: number
}

export type Media = {
  mediaId?: string,
  section: string,
  mediaType: string,
  fileInfo: MediaFileInfo[],
  uploadDateInfo: string
}

export type RequestOrg = {
  id: string,
  type: OganizationType,
  name: string,
  externalId: string,
  contact?: {
    privateEmail: string,
    businessEmail: string,
    privatePhone: string,
    businessPhone: string,
    privateMobilePhone: string,
    businessMobilePhone: string,
    fax: string
  },
  address: Address
}

export type ProductSpecs = {
  id: string,
  externalId: string,
  deprecated: boolean,
  type: string,
  name: string,
  family: {
    name: string,
    code: string
  },
  category: {
    name: string,
    code: string
  },
  class: {
    name: string,
    code: string
  },
  customFields: Record<string, string>
}

export type RequestItem = {
  orderId: string,
  itemIndex: number,
  product: OrderDetailItem,
  bigAndHeavy: boolean,
  requiredQuantity: number,
  approvedQuantity: number
}

export type RequestExceededItem = {
  order: Order,
  product: ProductSpecs,
  quantity: number,
  itemIndex: number,
  bigAndHeavy: boolean
}

export type RequestApproverNotes = {
  approvedType: ApprovedType,
  repairFee: number,
  returnFee: number,
  surveillanceFee: number,
  invoiceDataFee: number,
  note: string,
  customFields: Record<string, string>
}

export type RequestAcceptNotes = {
  partAccepted: boolean,
  partConditionAccepted: boolean,
  partConditionMedia: string[],
  reasonAccepted: boolean,
  administrativeCheckAccepted: boolean,
  createdOn: Date,
  containerCode: string,
  note: string,
  customFields: Record<string, string>,
  reasonCode: string
}

export type Request = {
  id: string,
  createdOn: Date,
  modifiedOn: Date,
  prettyName: string,
  barcode: string,
  vendor: RequestOrg,
  customer: RequestOrg,
  wrongProductExternalId?: string,
  deliveryOrganization: RequestOrg,
  note: string,
  isDeletable: boolean,
  requestType: RequestType,
  productType: 'SparePart' | 'Vehicle',
  items: RequestItem[],
  exceededItems: RequestExceededItem[],
  missingItems: RequestItem[],
  createdBy: {
    id: string,
    firstName: string,
    lastName: string
  },
  tracking: Tracking[],
  status: AllStatuses,
  requestReason?: RequestReason,
  media: Media[],
  hasMedia: boolean,
  acceptNotes: RequestAcceptNotes,
  approverNotes?: RequestApproverNotes,
  refusedNotes?: {
    note: string,
    customFields: Record<string, string>
  },
  toBeAmendedNotes?: {
    note: string,
    customFields: Record<string, string>
  },
  customFields: Record<string, string>
  tags: string[],
  warranty?: Warranty,
  paymentNotes?: {
    createdOn: string,
    note: string,
    customFields: Record<string, string>
  },
  customerClaimNotes?: {
    createdOn: string,
    note: string,
    customFields: Record<string, string>
  },
  vendorClaimNotes?: {
    createdOn: string,
    note: string,
    customFields: Record<string, string>
  }
};

export type RequestSearchItem = {
  productId: string,
  productExternalId: string,
  name: string,
  type: string,
  family: {
    code: string,
    name: string
  },
  category: {
    code: string,
    name: string
  },
  class: {
    code: string,
    name: string
  }
}

export type RequestSearchResult = {
  id: string,
  returnRequestId: string,
  createdOn: string,
  modifiedOn: string,
  status: string,
  prettyName: string,
  quantity: number,
  bigAndHeavy: boolean
  requestType: RequestType,
  orderId: string,
  item: RequestSearchItem,
  hasMedia: boolean,
  media: Media[],
  shopId: string,
  vendorId: string,
  vendorName: string,
  customerId: string,
  customerName: string,
  deliveryOrganizationId: string,
  deliveryOrganizationName: string,
  customFields: Record<string, string>,
  tags: string[]
};

export type Item = {
  id?: string,
  productId: string,
  orderItemIndex: number | null,
  quantity: number,
  bigAndHeavy: boolean,
  orderId: string | null
}

export type Warranty = {
  [key: string]: any
  vehicle: WarrantyVehicle | null,
  invoices: WarrantyInvoice[],
  note: string | null,
  diagnosis: string | null,
  solution: string | null,
  hoursSpent: number | null,
  ratePerHour: WarrantyHour | null
}

export type WarrantyVehicle = {
  [key: string]: any
  brand: string,
  model: string,
  vin: string,
  plate: string,
  registrationDate: string
}

export type WarrantyInvoice = {
  [key: string]: any
  date: string,
  number: string,
  mileage: number | null
}

export type WarrantyHour = {
  withVat: number,
  withoutVat: number,
  currentCurrency: string
}

export type RequestNew = {
  note: string,
  WrongProductExternalId: string | null
  requestType: RequestType,
  customerId: string,
  vendorId: string,
  productType: 'SparePart' | 'Vehicle',
  items: Item[],
  damagedPackaging: boolean,
  exceededItems: {
    productId: string,
    quantity: number,
    orderId: string | null,
    itemIndex: number | null,
    bigAndHeavy: boolean,
  }[],
  missingItems: {
    orderId: string,
    productId: string,
    orderItemIndex: number,
    quantity: number,
    bigAndHeavy: boolean
  }[],
  deliveryCompanyId: string | null,
  requestReason: RequestReason | null,
  warranty?: Warranty,
  customFields?: Record<string, string>
}

export type RequestUpdate = {
  note: string,
  requestReason: RequestReason | null,
  deliveryCompanyId: string,
  warranty: Warranty | null,
  customFields: Record<string, string>,
  tags: string[]
}

export type Statistic = {
  [key: string]: number
  all: number,
  completed: number,
  created: number,
  deleted: number,
  refusedAndAnomaly: number,
  underProcessing: number,
  validatedBO: number,
  incomplete: number
}

//---------------------------------------------------------

export const DEFAULT_REQUEST: RequestNew = {
  customerId: "",
  WrongProductExternalId: null,
  note: "",
  requestType: "",
  vendorId: "",
  deliveryCompanyId: "",
  requestReason: null,
  productType: 'SparePart',
  exceededItems: [],
  missingItems: [],
  items: [],
  damagedPackaging: false,
};

export const DEFAULT_WARRANTY_NAR: Warranty = {
  vehicle: {
    brand: "",
    model: "",
    vin: "",
    plate: "",
    registrationDate: ""
  },
  invoices: [
    { date: "", number: "", mileage: null },
    { date: "", number: "", mileage: null },
    { date: "", number: "", mileage: 0 },
    { date: "", number: "", mileage: 0 }
  ],
  note: "",
  diagnosis: "",
  solution: "",
  hoursSpent: 0,
  ratePerHour: {
    withVat: 0,
    withoutVat: 0,
    currentCurrency: ""
  }
};

export const DEFAULT_WARRANTY_AR: Warranty = {
  vehicle: null,
  invoices: [
    { date: "", number: "", mileage: null },
  ],
  note: null,
  diagnosis: null,
  solution: null,
  hoursSpent: null,
  ratePerHour: null
};

export const DEFAULT_REQUEST_FILTERS: RequestFilters = {
  all: "",
  sortField: "",
  sortAscending: false,
  pageIndex: 0,
  pageSize: 10,
  customerId: "",
  customer: DEFAULT_PARENT,
  externalId: "",
  requestType: "All",
  status: [],
  from: "",
  to: "",
  customFields: {},
  hasClaim: false
};

export type RequestSuggestFilters = {
  vendorIds?: string[],
  customerIds?: string[],
  startDate?: string,
  endDate?: string,
  text?: string,
  size?: number,
  tags?: string[]
};

export const DEFAULT_STATISTIC: Statistic = {
  all: 0,
  completed: 0,
  created: 0,
  deleted: 0,
  refusedAndAnomaly: 0,
  underProcessing: 0,
  validatedBO: 0,
  incomplete: 0
};

export const DEFAULT_ALERT: Alert = {
  frequencyFee: 0,
  hasSurveillance: false,
  invoiceFee: 0
};

export const DEFAULT_WAREHOUSE_ACCEPT: WarehouseAcceptOptions = {
  administrativeCheckAccepted: true,
  containerCode: "",
  customFields: {},
  id: "",
  note: "",
  partAccepted: true,
  partConditionAccepted: true,
  partConditionMedia: [],
  reasonAccepted: true,
  reasonCode: null
};