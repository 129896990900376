import { Tooltip, MenuItem, Checkbox, Typography, Stack, IconButton } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel, GridValidRowModel } from "@mui/x-data-grid";
import { useState } from "react";
import { OnlyFirstUpperCase } from "src/appUtils/string";
import MenuPopover from "src/components/menu-popover";
import SvgColor from "src/components/svg-color";
import { useLocales } from "src/locales";
import { alpha } from '@mui/material/styles';

interface VisibilityModelComponentProps<T extends GridValidRowModel> {
    columns?: GridColDef<T>[],
    model: GridColumnVisibilityModel
    onChangeModel: (model: GridColumnVisibilityModel) => void
}

export default function VisibilityModelComponent<T extends GridValidRowModel>({ columns, model, onChangeModel }: VisibilityModelComponentProps<T>) {

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const { translate } = useLocales();

    return (
        <>
            <Tooltip title={`${translate('list.tooltip.editColumns')}`}>
                <IconButton
                    onClick={(e) => setOpenPopover(e.currentTarget)}
                    size={'large'}
                    sx={{
                        height: "46px",
                        border: "1px",
                        borderStyle: "groove",
                        borderColor: (theme) => theme.palette.divider,
                        borderRadius: "10px",
                        color: (theme) => theme.palette.primary.main,
                        ":hover": { backgroundColor: (theme) => alpha(theme.palette.primary.lighter, 0.35) }
                    }}
                >
                    <SvgColor src={'/assets/icons/components/ic_edit_columns.svg'} sx={{ mx: 1 }} />
                </IconButton>
            </Tooltip>

            {/*             <Button
                startIcon={<SvgColor src={'/assets/icons/components/ic_edit_columns.svg'} sx={{ ml: 1.5 }} />}
                onClick={(e) => setOpenPopover(e.currentTarget)}
                size={'large'}
                sx={{
                    height: "46px",
                    border: "1px",
                    borderStyle: "groove",
                    borderColor: (theme) => theme.palette.divider,
                    borderRadius: "10px"
                }}
            /> */}

            <MenuPopover
                open={openPopover}
                onClose={() => setOpenPopover(null)}
                sx={{ maxHeight: '40vh', overflowY: 'auto' }}
            >
                <Stack>
                    {Object.entries(model).map(([key, val]) => (
                        <MenuItem
                            key={key}
                            onClick={() => onChangeModel({ ...model, [key]: !val })}
                            sx={{ display: 'flex', px: 3 }}
                        >
                            <Checkbox disableRipple checked={val} />
                            <Typography sx={{ mr: 2 }} variant='body2'>{columns?.find((col) => col.field === key)?.headerName || OnlyFirstUpperCase(key)}</Typography>
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </>
    );
}