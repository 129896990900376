import { Button, Container, Divider, MenuItem } from '@mui/material';
import Page from 'src/appComponents/Page';
import { useNavigate, } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import Label from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';
import useTabs from 'src/appHooks/useTabs';
import { FilterListType, QuickFilters } from 'src/@types/list';
import Iconify from 'src/components/iconify';
import { ShopFilters, DEFAULT_SHOP_FILTERS, ShopSearchResult, EnableDisableShopSliceProps, ShopStatusType } from 'src/@types/webshop';
import { webshopOperations } from 'src/redux/webshop';
import { setWebshopFiltersInUrl } from 'src/redux/webshop/webshop-slices';
import DateZone from 'src/appComponents/DateZone';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import { OnlyFirstLowerCase } from 'src/appUtils/string';
import { DEFAULT_SNACKBAR_PROPERTIES, ToolbarSearchFilters } from 'src/@types/commons';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { hasPermissions } from 'src/utils/user';

export default function WebshopList() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { isWebshopLoading, webshopList, webshopTotalCount, webshopFiltersInUrl, webshopStatistics } = useSelector((state: RootState) => state.webshop);

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('');

    const [filters, setFilters] = useState<ShopFilters>(DEFAULT_SHOP_FILTERS);

    const statsKeysToDelete: string[] = ["status"];

    const fullKeysToDelete: string[] = ["dateField", "min", "max"];

    //---- QUICKFILTERS START ----// 
    const quickFilters: QuickFilters[] = useMemo(() => [
        {
            key: '',
            label: `${translate('commons.all')}`,
            color: 'info',
        },
        {
            key: 'Published',
            label: `${translate('statuses.published')}`,
            color: 'success',
        },
        {
            key: 'Created',
            label: `${translate('statuses.created')}`,
            color: 'warning',
        },
        {
            key: 'Preview',
            label: `${translate('statuses.preview')}`,
            color: 'warning',
        },
        {
            key: 'Expired',
            label: `${translate('statuses.expired')}`,
            color: 'error',
        },
        {
            key: 'Disabled',
            label: `${translate('statuses.disabled')}`,
            color: 'error',
        },
        {
            key: 'Draft',
            label: `${translate('statuses.draft')}`,
            color: 'default',
        }
    ], [translate]);

    const renderQuickFilters = (key: string) => {
        if (webshopStatistics) {
            switch (key) {
                case "":
                    return webshopStatistics.all;
                case "Published":
                    return webshopStatistics.published;
                case "Created":
                    return webshopStatistics.created;
                case "Preview":
                    return webshopStatistics.preview;
                case "Expired":
                    return webshopStatistics.expired;
                case "Disabled":
                    return webshopStatistics.disabled;
                case "Draft":
                    return webshopStatistics.draft;
                default:
                    return 0;
            }
        } else return 0;
    };
    //---- QUICKFILTERS END ----//

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: ShopFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_SHOP_FILTERS[field])
        , []);

    //---- EXTRA FUNCTION TO USE BEFORE SEARCH ----//
    const extraSearchFiltersChecks = useCallback((searchFilters: ShopFilters) => {

        if ((searchFilters.min && searchFilters.max) && !searchFilters.dateField) searchFilters.dateField = DEFAULT_SHOP_FILTERS.dateField;

        return searchFilters;
    }, []);

    //---- TOOLBAR OPTIONS ----// "" | "" | "" | "",
    const toolbarFiltersList: ToolbarSearchFilters[] = useMemo(() =>
        [
            { key: 'CreatedOn', label: `${translate('statuses.created')}` },
            { key: 'PreviewDate', label: `${translate('statuses.preview')}` },
            { key: 'StartDate', label: `${translate('webshop.list.columns.publishing.start')}` },
            { key: 'EndDate', label: `${translate('webshop.list.columns.publishing.end')}` }
        ], [translate]);

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: FilterListType[] = useMemo(() => [
        {
            name: "partReferenceSection",
            label: translate(`request.partReference`),
            type: "Section"
        },
        {
            name: "partReference",
            label: translate(`webshop.list.filters.partReference`),
            type: "TextField"
        },
        {
            name: "partDescription",
            label: translate(`spareParts.list.tableHeaders.partDesc`),
            type: "TextField"
        },
        {
            name: "supplierFamily",
            label: translate(`spareParts.list.tableHeaders.supplierFamily`),
            type: "TextField"
        },
        {
            name: "price",
            label: translate(`webshop.list.filters.priceList`),
            type: "Slider",
            stepValue: 10,
            stepsNumber: 6
        }
    ], [translate]);

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<GridCellParams<ShopSearchResult> | null>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<ShopSearchResult>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.webshop.detail(params.row.id));
        }
    };

    const handleEdit = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.webshop.edit(id));
    }, [navigate]);

    const handleLogs = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.webshop.logs(id));
    }, [navigate]);

    const handleDuplicate = useCallback(async (id: string) => {

        let x = await dispatch(webshopOperations.duplicateShop(id));

        if (x.meta.requestStatus !== 'fulfilled') enqueueSnackbar(translate('commons.error'), DEFAULT_SNACKBAR_PROPERTIES);
        else enqueueSnackbar(translate('commons.enabled'), DEFAULT_SNACKBAR_PROPERTIES);

    }, [enqueueSnackbar, translate]);

    const handleArchive = useCallback(async (id: string) => {

        let x = await dispatch(webshopOperations.archiveShop(id));

        if (x.meta.requestStatus !== 'fulfilled') enqueueSnackbar(translate('commons.error'), DEFAULT_SNACKBAR_PROPERTIES);
        else enqueueSnackbar(translate('commons.enabled'), DEFAULT_SNACKBAR_PROPERTIES);

    }, [enqueueSnackbar, translate]);

    const handleEnableDisable = useCallback(async (id: string, action: "Enable" | "Disable") => {

        const options: EnableDisableShopSliceProps = {
            id: id,
            action: action,
            tab: filterStatus as ShopStatusType,
            filters: filters
        };

        let x = await dispatch(webshopOperations.enableDisableShop(options));

        if (x.meta.requestStatus !== 'fulfilled') enqueueSnackbar(translate('commons.error'), DEFAULT_SNACKBAR_PROPERTIES);
        else enqueueSnackbar(translate(action === "Enable" ? 'commons.enabled' : 'commons.disabled'), DEFAULT_SNACKBAR_PROPERTIES);

    }, [enqueueSnackbar, filterStatus, filters, translate]);

    const COLUMNS: GridColDef<ShopSearchResult>[] = useMemo(() => [
        {
            field: 'title',
            headerName: `${translate('commons.title')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.createdOn)}
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'previewDate',
            headerName: `${translate('webshop.list.columns.previewStart')}`,
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.previewDate)}
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'startDate',
            headerName: `${translate('webshop.list.columns.publishing.start')}`,
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.startDate)}
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'endDate',
            headerName: `${translate('webshop.list.columns.publishing.end')}`,
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.endDate)}
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'status',
            headerName: `${translate('commons.status')}`,
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {

                const status = OnlyFirstLowerCase(obj.row.status);

                return (
                    <Label
                        color={quickFilters.find((filter) => filter.key === status)?.color ?? "default"}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {translate(`statuses.${status}`)}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        object={obj}
                        currentRow={actualRow}
                        handleEdit={handleEdit}
                        handleLogs={handleLogs}
                        handleArchive={handleArchive}
                        handleDuplicate={handleDuplicate}
                        handleEnableDisable={handleEnableDisable}
                    />
                );
            }
        }
    ], [translate, isDesktop, quickFilters, openMenu, actualRow, handleEdit, handleLogs, handleArchive, handleDuplicate, handleEnableDisable]);
    //---- HANDLE TABLE END ----//

    return (
        <Page title={translate(`menu.management.webshop.title`)}>

            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={translate(`menu.management.webshop.title`)}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate(`role.type.webShop`) }
                    ]}
                    action={
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Create]}>
                            <Button
                                variant="contained"
                                startIcon={<Iconify icon={'eva:plus-fill'} />}
                                onClick={() => navigate(PATH_DASHBOARD.webshop.new)}
                                sx={{ borderRadius: "100px" }}
                            >
                                {translate('webshop.list.actions.new')}
                            </Button>
                        </PermissionBasedGuard>
                    }
                />

                <InfiniteScrollGenericList
                    list={webshopList}
                    isLoading={isWebshopLoading}
                    totalCount={webshopTotalCount}
                    defaultFilters={DEFAULT_SHOP_FILTERS}
                    specificStatsKeysToDelete={statsKeysToDelete}
                    specificFullKeysToDelete={fullKeysToDelete}
                    quickFilters={quickFilters}
                    renderQuickFilters={renderQuickFilters}
                    toolbarFiltersList={toolbarFiltersList}
                    filtersInSidebar={filtersInSidebar}
                    datagridColumns={COLUMNS}
                    updateCheckField={updateCheckField}
                    extraSearchFiltersChecks={extraSearchFiltersChecks}
                    context={"Webshop"}
                    setActualRow={setActualRow}
                    handleCellClick={handleCellClick}
                    setFiltersCallback={setFilters}
                    filterStatus={filterStatus}
                    onChangeFilterStatus={onChangeFilterStatus}
                    search={webshopOperations.searchShops}
                    searchStatistics={webshopOperations.getShopStatistics}
                    filtersInUrl={webshopFiltersInUrl}
                    setFiltersInUrl={setWebshopFiltersInUrl}
                    listDescription={translate('webshop.subtitle')}
                    resetList={() => { }}
                    showDates
                    datesNames={["min", "max"]}
                    dateSearchBy={["dateField", "all"]}
                //multipleView
                />

            </Container>
        </Page>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    object: GridCellParams<ShopSearchResult>,
    currentRow: GridCellParams<ShopSearchResult> | null,
    handleEdit: (id: string) => void,
    handleLogs: (id: string) => void,
    handleDuplicate: (id: string) => void,
    handleArchive: (id: string) => void,
    handleEnableDisable: (id: string, action: "Enable" | "Disable") => void
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleEdit, handleLogs, handleDuplicate, handleArchive, handleEnableDisable }: OptionsComponentProps) {

    const { translate } = useLocales();

    const { organization } = useUserOrganizationContext();

    const showFirstDivider: boolean =
        hasPermissions([OrganizationPermissionTypes.WebShop_Edit, OrganizationPermissionTypes.WebShop_Edit], organization.roles)
        &&
        hasPermissions([OrganizationPermissionTypes.WebShop_EnableDisable, OrganizationPermissionTypes.WebShop_Edit], organization.roles);

    const showSecondDivider: boolean =
        hasPermissions([OrganizationPermissionTypes.WebShop_EnableDisable, OrganizationPermissionTypes.WebShop_Edit], organization.roles)
        &&
        hasPermissions([OrganizationPermissionTypes.WebShop_BasketRule_ViewLogs], organization.roles);

    return (
        <TableMoreMenu
            showMenu={!!currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Edit]}>
                        <MenuItem
                            onClick={() => {
                                handleEdit(object.id.toString());
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.edit")}`}
                        </MenuItem>
                    </PermissionBasedGuard>

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Edit]}>
                        <MenuItem
                            onClick={() => {
                                handleDuplicate(object.id.toString());
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("webshop.list.actions.duplicate")}`}
                        </MenuItem>
                    </PermissionBasedGuard>

                    {showFirstDivider && <Divider sx={{ my: "0 !important" }} />}

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_EnableDisable]}>
                        <MenuItem
                            onClick={() => {
                                if (handleEnableDisable) {
                                    if (object.row.enabled) handleEnableDisable(object.id.toString(), "Disable");
                                    else handleEnableDisable(object.id.toString(), "Enable");
                                }
                                handleCloseMenu();
                            }}
                            sx={{ color: object.row.enabled ? 'error.main' : 'success.main' }}
                        >
                            {translate(`commons.${!object.row.enabled ? "enable" : "disable"}`)}
                        </MenuItem>
                    </PermissionBasedGuard>

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Edit]} >
                        <MenuItem
                            onClick={() => {
                                handleArchive(object.id.toString());
                                handleCloseMenu();
                            }}
                            sx={{ color: 'error.main' }}
                        >
                            {translate("webshop.list.actions.archive")}
                        </MenuItem>
                    </PermissionBasedGuard>

                    {showSecondDivider && <Divider sx={{ my: "0 !important" }} />}

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_BasketRule_ViewLogs]} >
                        <MenuItem
                            onClick={() => {
                                handleLogs(object.id.toString());
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.logs")}`}
                        </MenuItem>
                    </PermissionBasedGuard>
                </>
            }
        />
    );
}

type SingleGridElementProps = {
};

function SingleGridElement({ }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <></>
    );
}