import { Box, Button, Card, Checkbox, Divider, FormControlLabel, InputAdornment, Modal, Radio, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { PATH_AUTH, PATH_DASHBOARD } from "src/routes/paths";
import Page from "src/appComponents/Page";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "src/appHooks/useAuth";
import useResponsive from "src/hooks/useResponsive";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import { useLocales } from "src/locales";
import { DEFAULT_USER_ORGANIZATION, UserOrganization } from "src/@types/user";
import Iconify from "src/components/iconify";
import useTenant from "src/appHooks/useTenant";

const backgroundImages = [
    '/assets/{0}/001.webp',
    '/assets/{0}/002.webp',
    '/assets/{0}/003.webp',
    '/assets/{0}/004.webp',
    '/assets/{0}/005.webp',
    '/assets/{0}/006.webp',
    '/assets/{0}/007.webp',
    '/assets/{0}/008.webp',
    '/assets/{0}/009.webp',
    '/assets/{0}/010.webp',
    '/assets/{0}/011.webp',
    '/assets/{0}/012.webp',
    '/assets/{0}/013.webp',
    '/assets/{0}/014.webp',
    '/assets/{0}/015.webp'
];

const rand = Math.floor(Math.random() * backgroundImages.length + 1);

export default function OrganizationSelector() {

    const isDesktop = useResponsive('up', 'sm');

    const navigate = useNavigate();

    const { user, logout } = useAuth();

    const tenant = useTenant();

    const [selected, setSelected] = useState<UserOrganization>(DEFAULT_USER_ORGANIZATION);

    const [remember, setRemember] = useState(false);

    const [searchQuery, setSearchQuery] = useState("");

    const parsed = useMemo(() => {
        if (!searchQuery)
            return user.organizations;

        return user.organizations.filter(v =>
            v.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            v.externalId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            v.type.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [searchQuery, user.organizations]);

    const { translate } = useLocales();

    const { changeValues, remember: rem, organizationId: organization, organization: userOrg } = useUserOrganizationContext();

    useEffect(() => {
        if (user.organizations.length === 1) {
            changeValues(false, user.organizations[0]);
            navigate(PATH_DASHBOARD.root);
        }
        else if (user.organizations.length === 0)
            navigate(PATH_DASHBOARD.root);
    }, [user, navigate]);

    useEffect(() => {
        let userOrg = user.organizations.find(org => org.organizationId === organization);

        if (rem && organization && !!userOrg) {
            changeValues(rem, userOrg);
            navigate(PATH_DASHBOARD.root);
        }
    }, [navigate, organization, rem, user]);

    const handleSubmit = () => {
        changeValues(remember, selected);
        navigate(PATH_DASHBOARD.root);
    };

    const setOpen = (): boolean => {
        if (user.organizations.length <= 1)
            return false;

        if (!rem)
            return true;

        if (!organization)
            return true;

        if (!user.organizations.find(org => org.organizationId === organization))
            return true;

        if (userOrg.organizationId !== organization)
            return true;

        return false;
    };

    return (
        <Page title={`${translate('menu.management.organizationSelector.title')}`} >
            <Box>
                <img
                    src={backgroundImages[rand].replace('{0}', tenant.id)}
                    alt="background"
                    style={{
                        objectFit: 'cover',
                        width: '100vw',
                        height: '100vh',
                    }}
                />
                <Modal
                    open={setOpen()}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: isDesktop ? '40vw' : '75vw',
                            outline: 'none'
                        }}
                    >
                        <Box
                            component="img"
                            src={`/assets/${process.env.REACT_APP_TENANT}/logo-dark.svg`}
                            sx={{ width: isDesktop ? '60%' : '80%', height: 50, display: 'inline-block', my: 2, transform: isDesktop ? 'translate(35%)' : 'translate(14%)' }}
                        />
                        <Card sx={{ p: 3 }}>

                            <Typography variant="h6">{`${translate('organization.detail.organizationSelect')}`}</Typography>
                            {user.organizations.length > 3 &&
                                <TextField
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    label={`${translate('commons.search')}`}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder={`${translate('organization.list.filter.searchOrg')}`}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify
                                                    icon={'eva:search-fill'}
                                                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            <Box sx={{ mt: 3, overflowY: 'auto', maxHeight: '40vh' }}>
                                {
                                    parsed.map((org, ind) =>
                                        <Box
                                            key={ind}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                borderRadius: '8px',
                                                border: '1px #E2E2E2 solid',
                                                my: 2, px: 2,
                                                alignItems: 'center',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => setSelected(org)}
                                        >
                                            <Box sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <Typography variant="subtitle1">
                                                    {org.type}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {org.externalId ? org.externalId : ""} {org.name}
                                                </Typography>
                                            </Box>
                                            <Radio checked={selected.organizationId === org.organizationId} />
                                        </Box>
                                    )
                                }
                            </Box>

                            <Divider sx={{ mb: isDesktop ? 2 : 1 }} />

                            <Box sx={{
                                display: 'flex',
                                px: isDesktop ? 3 : 0,
                                pt: isDesktop ? 3 : 0,
                                pb: 3,
                                gap: isDesktop ? 1 : 1.5,
                                flexDirection: isDesktop ? 'row' : 'column-reverse'
                            }}
                            >
                                <Button
                                    variant="soft"
                                    onClick={() => {
                                        logout();
                                        navigate(PATH_AUTH.login, { replace: true });
                                    }}
                                >
                                    {`${translate('commons.logout')}`}
                                </Button>

                                <Box
                                    sx={{
                                        ml: isDesktop ? 'auto' : undefined,
                                        display: 'flex',
                                        gap: isDesktop ? 1 : 5,
                                        flexDirection: isDesktop ? 'row' : 'column'
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={remember}
                                                onChange={(e) => setRemember(e.target.checked)}
                                            />
                                        }
                                        label={`${translate('commons.rememberLastLogin')}`}
                                    />

                                    <Button
                                        sx={{
                                            ml: isDesktop ? 1 : 0,
                                            borderRadius: '8px'
                                        }}
                                        onClick={() => handleSubmit()}
                                        disabled={!selected.organizationId}
                                        variant="contained"
                                    >
                                        {`${translate('commons.next')}`}
                                    </Button>

                                </Box>

                            </Box>
                        </Card>
                    </Box>
                </Modal>
            </Box>

        </Page>
    );
}