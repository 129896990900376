import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocales } from 'src/locales';
import { ToolbarSearchFilters } from 'src/@types/commons';
import { useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { isEqual, remove } from 'lodash';

interface GenericFilterToolbarProps {
    filterValues: any,
    defaultFiltersValues: any,
    onSearch: VoidFunction,
    onResetAll: VoidFunction,
    optionsFields?: ToolbarSearchFilters[],
    showDates?: boolean,
    datesNames?: [string, string],
    dateSearchBy?: [string, string],
    showSummary?: boolean,
    setShowSummary?: (value: boolean) => void,
    propFullKeysToRemove?: string[]
}

const DEFAULT_DATE_ERRORS = {
    fromFormat: false,
    fromMissing: false,
    toFormat: false,
    toMissing: false,
    toInvalid: false
};

/**
 * 
 * @param filterValues             actual values of filters
 * @param defaultFiltersValues     default values of filters
 * @param onSearch                 function called when clicking the search button 
 * @param onResetAll               function called when clicking the delete button 
 * @param optionsFields    
 * @param showDates                boolean: used to show dates filters
 * @param datesNames               [string, string]: used when dates filters have different names then the default ones (["from","to"])
 * @param dateSearchBy             [string, string]: used when the searchBy select is referring to dates instead of TextField. First string is the name of the select filter, second string is the value associated to the TextField  
 * 
 */

export default function GenericFilterToolbar({ filterValues, defaultFiltersValues, onSearch, onResetAll, optionsFields, showDates, showSummary, setShowSummary, datesNames, dateSearchBy, propFullKeysToRemove }: GenericFilterToolbarProps) {

    const { translate } = useLocales();

    const { setValue } = useFormContext();

    const [resetFilter, setResetFilter] = useState(false);

    const [openTooltip, setOpenTooltip] = useState(false);

    const [showDeleteButton, setShowDeleteButton] = useState(false);

    const [datesErrors, setDatesErrors] = useState(DEFAULT_DATE_ERRORS);

    const dateFromName = datesNames ? datesNames[0] : "from";

    const dateToName = datesNames ? datesNames[1] : "to";

    const [toolbarFilterValues, setToolbarFilterValues] = useState(
        {
            ...filterValues,
            searchType: optionsFields ? optionsFields[0].key : "all",
            searchValue: ""
        }
    );

    //-----------------------------------------------------------------------------------

    //---- IS DEFAULT - START ----//
    const fullKeysToRemove: string[] = useMemo(() => {
        if (propFullKeysToRemove) return propFullKeysToRemove;
        else return ["pageIndex", "pageSize", "sortField", "sortAscending"];
    }, [propFullKeysToRemove]);

    const fullKeyRemover = useCallback((key: string) => { return !(fullKeysToRemove.includes(key)); }, [fullKeysToRemove]);

    const isDefaultSimple = useCallback((filter: any) => {
        const found = remove(Object.keys(filter), fullKeyRemover)
            .find((element) => (filter[element] && filter[element] !== defaultFiltersValues[element]));

        return (!found);
    }, [defaultFiltersValues, fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    const searchServiceAndValue = useCallback(() => {

        let returnOption: string = "all";

        if (dateSearchBy) {
            returnOption = filterValues[dateSearchBy[0]];
        } else {
            returnOption = optionsFields ? (optionsFields.find((option) => filterValues[option.key])?.key ?? optionsFields[0].key) : "all";
        }

        return returnOption;

    }, [dateSearchBy, filterValues, optionsFields]);

    useEffect(() => {

        if (!isDefaultSimple(filterValues) && !showSummary) {

            const searchType = searchServiceAndValue();

            let newFilterValues = {
                ...filterValues,
                searchType: searchType,
                searchValue: dateSearchBy ? filterValues[dateSearchBy[1]] : (filterValues[searchType] || "")
            };

            newFilterValues[dateFromName] = filterValues[dateFromName] || defaultFiltersValues[dateFromName];

            newFilterValues[dateToName] = filterValues[dateToName] || defaultFiltersValues[dateToName];

            setToolbarFilterValues(newFilterValues);

            if (!filterValues[dateFromName]) {
                setDatesErrors(DEFAULT_DATE_ERRORS);
            }
            if (filterValues[dateFromName] || filterValues[searchType]) {
                setShowDeleteButton(true);
            } else {
                setShowDeleteButton(false);
            }
        } else {
            let newFilterValues = {
                ...filterValues,
                searchType: optionsFields ? optionsFields[0].key : "all",
                searchValue: ""
            };

            newFilterValues[dateFromName] = defaultFiltersValues[dateFromName];

            newFilterValues[dateToName] = defaultFiltersValues[dateToName];

            setToolbarFilterValues(newFilterValues);
            setDatesErrors(DEFAULT_DATE_ERRORS);
            setShowDeleteButton(false);
        }

    }, [dateFromName, dateSearchBy, dateToName, defaultFiltersValues, filterValues, isDefaultSimple, optionsFields, searchServiceAndValue, showSummary]);

    useEffect(() => {
        if (!toolbarFilterValues[dateFromName] && !toolbarFilterValues[dateToName] && !isEqual(DEFAULT_DATE_ERRORS, datesErrors)) {
            setDatesErrors(DEFAULT_DATE_ERRORS);
        }
    }, [dateFromName, dateToName, datesErrors, toolbarFilterValues]);

    useEffect(() => {
        if (resetFilter) {
            let newFilterValues = {
                ...defaultFiltersValues,
                searchType: optionsFields ? optionsFields[0].key : "all",
                searchValue: ""
            };

            newFilterValues[dateFromName] = defaultFiltersValues[dateFromName];

            newFilterValues[dateToName] = defaultFiltersValues[dateToName];

            setToolbarFilterValues(newFilterValues);
            setResetFilter(false);
            onResetAll();
        }
    }, [resetFilter, onResetAll, defaultFiltersValues, optionsFields, dateFromName, dateToName]);

    //-----------------------------------------------------------------------------------

    const handleChangeValues = (newValue: any, name: string) => {

        const valuesCopy = JSON.parse(JSON.stringify(toolbarFilterValues));

        valuesCopy[name] = newValue;

        setToolbarFilterValues(valuesCopy);
    };

    const setAllValues = () => {

        const optionsKeys = optionsFields?.map(({ key, label }) => key) || [];

        for (const [key, value] of Object.entries(toolbarFilterValues)) {

            if (key !== "searchType" && key !== "searchValue") {

                if (key === dateFromName || key === dateToName) {
                    setValue(key, value);
                } else if (dateSearchBy && dateSearchBy[0] === key) {
                    setValue(dateSearchBy[0], toolbarFilterValues.searchType);
                    setValue(dateSearchBy[1], toolbarFilterValues.searchValue);
                } else if (toolbarFilterValues.searchType === key) {
                    setValue(key, toolbarFilterValues.searchValue);
                } else if (optionsKeys.includes(key)) {
                    setValue(key, defaultFiltersValues[key]);
                }
            }
        }
    };

    const handleSearch = () => {
        if (toolbarFilterValues[dateFromName] && !toolbarFilterValues[dateToName]) {
            setDatesErrors({ ...datesErrors, toMissing: true });
        } else if (!toolbarFilterValues[dateFromName] && toolbarFilterValues[dateToName]) {
            setDatesErrors({ ...datesErrors, fromMissing: true });
        } else if (toolbarFilterValues[dateFromName] || toolbarFilterValues[dateToName]) {
            setAllValues();
            onSearch();
            setShowDeleteButton(true);
            if (setShowSummary) setShowSummary(false);
        }
    };

    const handleDelete = () => {
        setResetFilter(true);
        setDatesErrors(DEFAULT_DATE_ERRORS);
        setShowDeleteButton(false);
    };

    //----------DATES MANAGEMENT-----------//

    const handleChangeFrom = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            handleChangeValues(date.format('YYYY-MM-DD'), dateFromName);

            let toInvalidCheck = false;

            if (toolbarFilterValues[dateToName] && dayjs(toolbarFilterValues[dateToName]).diff(date, "d") < 0) {
                toInvalidCheck = true;
            }

            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: false, toInvalid: toInvalidCheck });
        }
        else {
            setDatesErrors({ ...datesErrors, fromFormat: true });
        }
    };

    const handleChangeTo = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            if (date.diff(toolbarFilterValues[dateFromName], "d") < 0) {
                setDatesErrors({ ...datesErrors, toInvalid: true, toFormat: false, toMissing: false });
            } else {
                handleChangeValues(date.format('YYYY-MM-DD'), dateToName);
                setDatesErrors({ ...datesErrors, toInvalid: false, toFormat: false, toMissing: false });
            }
        }
        else {
            setDatesErrors({ ...datesErrors, toFormat: true });
        }
    };

    const handleDeleteDate = (type: string) => {

        handleChangeValues("", type);

        if (type === dateFromName) {
            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: false, toInvalid: false });
        } else {
            setDatesErrors({ ...datesErrors, toFormat: false, toMissing: false, toInvalid: false });
        }
    };

    const errorChecker = () => {
        if (datesErrors.fromFormat) return true;
        if (datesErrors.fromMissing) return true;
        if (datesErrors.toFormat) return true;
        if (datesErrors.toMissing) return true;
        if (datesErrors.toInvalid) return true;

        return false;
    };

    const getDateErrorMessage = (type: string) => {

        if (datesErrors.fromFormat && type === dateFromName) return `${translate("commons.validation.wrongDate")}`;
        if (datesErrors.toFormat && type === dateToName) return `${translate("commons.validation.wrongDate")}`;
        if (datesErrors.fromMissing && type === dateFromName) return `${translate("commons.validation.missingDate")}`;
        if (datesErrors.toMissing && type === dateToName) return `${translate("commons.validation.missingDate")}`;
        if (datesErrors.toInvalid && type === dateToName) return `${translate("commons.validation.invalidDate")}`;

        return "";
    };

    return (
        <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{ px: 1.5, pt: 2.5, pb: { xs: 0, md: 2.5 }, display: 'flex', alignItems: 'center' }}
        >

            {optionsFields &&
                <FormControl
                    sx={{
                        minWidth: { xs: "100%", md: 180 },
                        mr: 2, mb: { xs: 2, md: 0 }
                    }}
                    variant="outlined"
                >
                    <InputLabel sx={{ '&.Mui-focused': { color: 'grey.500' } }}>
                        {`${translate('returns.searchType')}`}
                    </InputLabel>

                    <Select
                        value={toolbarFilterValues.searchType}
                        input={<OutlinedInput label={`${translate('returns.searchType')}`} />}
                        onChange={(event) => handleChangeValues(event.target.value, "searchType")}
                    >
                        {optionsFields.map((option) => (
                            <MenuItem
                                key={option.key}
                                value={option.key}
                                sx={{
                                    mx: 1, my: 0.5,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    textTransform: 'capitalize'
                                }}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }

            {showDates &&
                <Box
                    sx={{
                        minWidth: { xs: "100%", md: optionsFields ? 400 : 450 },
                        mr: 2,
                        mb: { xs: 2, md: 0 }
                    }}
                >
                    <Stack
                        spacing={2}
                        direction={{ xs: 'column', sm: 'row' }}
                    >
                        <DatePicker
                            label={`${translate('commons.from')}`}
                            views={['year', 'month', 'day']}
                            format={'dd/MM/yyyy'}
                            value={filterValues[dateFromName] ? dayjs(filterValues[dateFromName]).toDate() : null}

                            onAccept={(newValue) => {
                                if (newValue)
                                    handleChangeValues(dayjs(newValue).format('YYYY-MM-DD'), dateFromName);
                            }}

                            onChange={(fromDateValue, inputval) => {

                                if (!inputval.validationError && fromDateValue) {

                                    const date = dayjs(fromDateValue);

                                    handleChangeFrom(date);
                                } else {
                                    setDatesErrors({ ...datesErrors, fromFormat: true });
                                }
                            }}

                            slotProps={{
                                textField: {
                                    name: dateFromName,
                                    fullWidth: true,
                                    error: datesErrors.fromFormat || datesErrors.fromMissing,
                                    helperText: getDateErrorMessage(dateFromName),
                                    InputProps: {
                                        startAdornment: (filterValues[dateFromName] &&
                                            <InputAdornment position="end">
                                                <IconButton
                                                    sx={{ ml: -2.5, mr: 0 }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleDeleteDate(dateFromName);
                                                    }}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                }
                            }}
                        />

                        <DatePicker
                            label={`${translate('commons.to')}`}
                            views={['year', 'month', 'day']}
                            format={'dd/MM/yyyy'}
                            value={filterValues[dateToName] ? dayjs(filterValues[dateToName]).toDate() : null}

                            onAccept={(newValue) => {
                                if (newValue)
                                    handleChangeValues(dayjs(newValue).format('YYYY-MM-DD'), dateToName);
                            }}

                            onChange={(fromDateValue, inputval) => {
                                if (!inputval.validationError && fromDateValue) {

                                    const date = dayjs(fromDateValue);

                                    handleChangeTo(date);
                                } else {
                                    setDatesErrors({ ...datesErrors, toFormat: true });
                                }
                            }}

                            slotProps={{
                                textField: {
                                    name: dateToName,
                                    fullWidth: true,
                                    error: datesErrors.toFormat || datesErrors.toMissing || datesErrors.toInvalid,
                                    helperText: getDateErrorMessage(dateToName),
                                    InputProps: {
                                        startAdornment: (filterValues[dateToName] &&
                                            <InputAdornment position="end">
                                                <IconButton
                                                    sx={{ ml: -2.5, mr: 0 }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleDeleteDate(dateToName);
                                                    }}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                }
                            }}
                        />

                    </Stack>
                </Box>
            }

            <TextField
                type="search"
                value={toolbarFilterValues.searchValue}
                onChange={(event) => handleChangeValues(event.target.value, "searchValue")}
                placeholder={`${translate('commons.search')}`}
                sx={{ width: "100%", mr: 2 }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        handleSearch();
                    }
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify
                                icon={'eva:search-fill'}
                                sx={{ color: 'text.disabled', width: 20, height: 20 }}
                            />
                        </InputAdornment>
                    ),
                }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Tooltip
                    title={`${translate("commons.validation.invalidData")}`}
                    disableFocusListener
                    disableTouchListener
                    disableInteractive
                    open={openTooltip}
                    sx={{ mb: () => errorChecker() ? 4 : 0, width: { xs: "100%" } }}
                >
                    <Box
                        onMouseEnter={() => { if (errorChecker()) setOpenTooltip(true); }}
                        onMouseLeave={() => { if (errorChecker()) setOpenTooltip(false); }}
                    >
                        <Button
                            startIcon={<Iconify icon={'bx:search-alt'} sx={{ ml: 1.5, mt: 0.5 }} />}
                            onClick={handleSearch}
                            disabled={errorChecker()}
                            size={"large"}
                            sx={{ mt: 0.5, width: { xs: "100%" } }}
                        />
                    </Box>
                </Tooltip>

                <Button
                    startIcon={<DeleteIcon sx={{ ml: 1.5, mt: 0.75 }} />}
                    onClick={handleDelete}
                    size={"large"}
                    sx={{
                        display: `${(showDeleteButton) ? "inline" : "none"}`,
                        mt: 0.5, width: { xs: "100%" }
                    }}
                />
            </Box>

        </Stack>
    );
}

